import { MutableRefObject, useEffect } from 'react';

const useOutsideClick = (
  ref: MutableRefObject<HTMLElement | null>,
  callback: (event: MouseEvent) => void
) => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      // Guard against null ref and invalid target
      if (!ref.current || !(event.target instanceof Node)) {
        return;
      }

      if (!ref.current.contains(event.target)) {
        callback(event);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ref;
};

export { useOutsideClick };
