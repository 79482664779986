import { addressKey } from '@/config/query-keys';
import { addressesService } from '@/services/addresses.service';
import { HttpError } from '@/types/error';
import { SeemisAddress } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useAddresses(
  // TODO: Type query with @admissions-support/types once the BE PRs are merged
  query: { postCode: string },
  queryOptions?: Partial<UseQueryOptions<SeemisAddress[], HttpError>>
) {
  return useQuery({
    queryKey: addressKey.byPostCode(query.postCode),
    queryFn: () => addressesService.getAddresses(query),
    ...queryOptions,
  });
}

export { useAddresses };
