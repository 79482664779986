import { Box } from '@/components/box';
import { BookingSummary } from '@/components/extra-hours-bookings/booking-summary-modal';
import { FallbackComponent } from '@/components/fallback-component';
import { LoadingScreen } from '@/components/loading-screen';
import { Table } from '@/components/table';
import { parentRouterPath } from '@/config/route-paths.config';
import { useCreateBookingMutation } from '@/hooks/create-hooks/use-create-booking-mutation';
import { useAvailableExtraHours } from '@/hooks/query-hooks/use-available-extra-hours';
import { useChildBookingsInfo } from '@/hooks/query-hooks/use-child-bookings-info';
import { useChildBookingMeta } from '@/hooks/use-child-booking-meta';
import { getErrorMessage } from '@/utils/get-error-message';
import {
  getDuplicatedSelectedExtraHours,
  getExtraHoursByIds,
} from '@/utils/product-utils';
import { ExtraHoursAvailableToBook } from '@admissions-support/types';
import { RowSelectionState, createColumnHelper } from '@tanstack/react-table';
import { SearchRefraction } from '@untitled-ui/icons-react';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Navigate,
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

let toastId: string;

function AvailableExtraHours() {
  const params = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from');
  const to = searchParams.get('to');
  const selectedDays = searchParams.getAll('days');
  const location = searchParams.get('location');
  const currentSchoolYear = searchParams.get('termId');
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const navigate = useNavigate();

  const childId = params.id || '';
  const selectedExtraHourIds = Object.keys(rowSelection);

  const {
    data: bookingMeta,
    isSuccess: isBookingMetaSuccess,
    isLoading: isBookingMetaLoading,
    isError: isBookingMetaError,
  } = useChildBookingsInfo(childId, {
    refetchOnWindowFocus: false,
  });

  const {
    data: childMeta,
    isSuccess: isChildMetaSuccess,
    isLoading: isChildMetaLoading,
    isError: isChildMetaError,
  } = useChildBookingMeta(childId, currentSchoolYear || '', {
    refetchOnWindowFocus: false,
  });

  const {
    data: extraHours,
    isSuccess: isExtraHoursSuccess,
    isLoading: isExtraHoursLoading,
    isError: isExtraHoursError,
    isFetching: isExtraHoursFetching,
    error: extraHoursError,
  } = useAvailableExtraHours(
    childId,
    {
      from: from || '',
      /**
       * For some reason the BE doesn't give any extrahours if the to property is omitted
       * if you run the BE on localhost and change the api url base to http://localhost:3000/ it works just fine
       */
      to: to || from || '',
      days: selectedDays,
      location: location || '',
      schoolYear: currentSchoolYear || '',
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(childMeta?.ratio.id),
      staleTime: 0,
    }
  );

  const { mutateAsync, isPending: isBookingCreating } =
    useCreateBookingMutation(childId, {
      onMutate: () => {
        toastId = toast.loading('Booking in progress...');
      },
      onSuccess: async () => {
        toast.success('Booking successfull!', {
          id: toastId,
        });
        navigate(
          `${generatePath(parentRouterPath.CHILD_PURCHASE_EXTRA_HOURS, {
            id: childId,
          })}?booked=${selectedExtraHourIds.length}`
        );
      },
      onError: error => {
        const formattedError = Array.isArray(error)
          ? error.join(', ')
          : Array.isArray(error.message)
          ? error.message.join(', ')
          : error.message;

        toast.error(formattedError, {
          id: toastId,
        });
      },
    });

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const handleBooking = async () => {
    await mutateAsync({
      extraHoursId: selectedExtraHourIds,
    });
  };

  const handleRefineSearch = () => {
    navigate(
      `${generatePath(parentRouterPath.CHILD_NEW_BOOKING, {
        id: childId,
      })}?${searchParams}`
    );
  };

  const isBookingDisabled =
    isBookingMetaSuccess && !bookingMeta.isBookingEnabled;

  const columnHelper = createColumnHelper<ExtraHoursAvailableToBook>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('date', {
        id: 'date',
        cell: info => {
          const dateAsString = format(info.getValue(), 'dd/MM/yyyy');
          return <span className="font-mono">{dateAsString}</span>;
        },
        header: () => <span>Date</span>,
        enableSorting: true,
        size: 116,
      }),
      columnHelper.accessor('location.name', {
        id: 'location',
        cell: info => {
          return info.getValue();
        },
        header: () => <span>Location</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('productName', {
        cell: info => {
          return info.getValue();
        },
        header: () => <span>Product</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('day', {
        cell: info => {
          return info.getValue();
        },
        header: () => <span>Day</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('time', {
        cell: info => {
          return <span className="font-mono">{info.getValue()}</span>;
        },
        header: () => <span>Time</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('price', {
        cell: info => {
          return <span className="font-mono">£{info.getValue()}</span>;
        },
        header: () => <span>Cost</span>,
        enableSorting: true,
        size: 99,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (isBookingMetaLoading || isExtraHoursLoading || isChildMetaLoading) {
    return (
      <Box>
        <p className="mb-6 flex items-center text-lg font-semibold">
          Available Sessions
        </p>

        <LoadingScreen />
      </Box>
    );
  }

  if (
    isBookingMetaError ||
    !isBookingMetaSuccess ||
    isExtraHoursError ||
    !isExtraHoursSuccess ||
    isChildMetaError ||
    !isChildMetaSuccess
  ) {
    return (
      <Box>
        <FallbackComponent />
      </Box>
    );
  }

  if (isBookingDisabled) {
    return (
      <Navigate
        to={generatePath(parentRouterPath.CHILD_OVERVIEW, { id: childId })}
      />
    );
  }

  const isRowSelected = Object.keys(rowSelection).length > 0;
  const availableExtraHours = isExtraHoursSuccess ? extraHours : [];

  const selectedExtraHours = getExtraHoursByIds({
    extraHourIds: selectedExtraHourIds,
    extraHours: availableExtraHours,
  });

  const daysWithMultipleBooking = getDuplicatedSelectedExtraHours({
    extraHours: selectedExtraHours,
  }).map(day => format(day, 'dd/MM/yyyy'));

  return (
    <Box>
      <div className="mb-6 items-center justify-between sm:flex">
        <p className="mb-6 flex items-center text-lg font-semibold sm:mb-0">
          Available Sessions
        </p>

        <button className="btn btn-secondary" onClick={handleRefineSearch}>
          Edit Search
        </button>
      </div>
      <BookingSummary
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        nbOfExtraHoursToBook={selectedExtraHourIds.length}
        daysWithMultipleBooking={daysWithMultipleBooking}
        onSubmit={handleBooking}
        isLoading={isBookingCreating}
      />
      <Table
        columns={columns}
        data={extraHours}
        className="mt-5"
        isFetching={isExtraHoursFetching}
        isLoading={isExtraHoursLoading}
        error={getErrorMessage(extraHoursError)}
        EmptyState={
          <div className="py-12 text-center">
            <SearchRefraction
              className="mx-auto h-8 w-8 text-gray-400"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No Extra Hour Found
            </h3>
          </div>
        }
        onRowSelectionChange={setRowSelection}
        rowSelection={rowSelection}
        rowSelectionType="manual"
        getRowId={extraHour => extraHour.id}
        paginationType="auto"
        pageSize={20}
        sortingType="auto"
        sorting={[{ id: 'date', desc: false }]}
        //TODO: add selection header
        SelectionHeader={<div></div>}
      />

      <button
        type="button"
        className="btn btn-primary mt-4"
        disabled={!isRowSelected}
        onClick={() => setIsConfirmModalOpen(true)}
      >
        Book Selected
      </button>
    </Box>
  );
}

export { AvailableExtraHours };
