import { renderItem } from '@/components/sidebar';
import { NavigationItem } from '@/types/navigation';

type ParentsApplicationNavigationProps = {
  navigation: NavigationItem[];
};

function ParentsApplicationNavigation(
  props: ParentsApplicationNavigationProps
) {
  const { navigation } = props;

  const currentStep = navigation.findIndex(item =>
    location.pathname.startsWith(item.href)
  );

  const lastEnabledStep = (() => {
    for (let i = navigation.length - 1; i > currentStep; i--) {
      if (!navigation[i].disabled) {
        return i;
      }
    }
    return -1;
  })();
  const isStepComplete = (index: number) => {
    return (
      index < currentStep ||
      (index > currentStep &&
        !navigation[index].disabled &&
        index !== lastEnabledStep)
    );
  };
  return (
    <nav className="flex flex-1 flex-col" aria-label="Sidebar">
      <ul className="-mx-2 space-y-1">
        {navigation.map((item, index) =>
          renderItem(item, isStepComplete(index))
        )}
      </ul>
    </nav>
  );
}

export { ParentsApplicationNavigation };
