import { RadioGroup } from '@headlessui/react';
import { isFunction } from '@tanstack/react-table';
import { get } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

type RadioBoxOptionProps = {
  checked?: boolean;
};

type RadioBoxOption = {
  value: string;
  Content: React.ComponentType<RadioBoxOptionProps>;
  disabled?: boolean;
};

type RadioBoxProps = {
  name: string;
  containerClassName?: string;
  optionContainerClassName?: string;
  label?: string;
  options: RadioBoxOption[];
  disabled?: boolean;
  onActiveClick?: (value: string) => void;
};

type RadioBoxBaseProps = {
  onChange: (value: string) => void;
  value?: string | number;
  error?: string;
  defaultValue?: string | number;
} & Omit<RadioBoxProps, 'name'>;

function RadioBoxBase({
  label,
  options,
  onChange,
  value,
  error,
  disabled,
  containerClassName,
  optionContainerClassName,
  onActiveClick,
  defaultValue,
}: RadioBoxBaseProps) {
  const handleActiveClick = (currentValue: string) => () => {
    if (currentValue === value && isFunction(onActiveClick)) {
      onActiveClick(currentValue);
    }
  };

  return (
    <RadioGroup
      onChange={onChange}
      value={value || ''}
      defaultValue={defaultValue || undefined}
    >
      <RadioGroup.Label className="label mb-1">{label}</RadioGroup.Label>
      <div className={containerClassName}>
        {options.map(option => (
          <RadioGroup.Option
            key={option.value}
            value={option.value}
            disabled={disabled || option.disabled}
            onClick={
              disabled || option.disabled
                ? undefined
                : handleActiveClick(option.value)
            }
            className={({ checked }) =>
              twMerge(
                checked
                  ? 'z-10 border-primary-600 bg-primary-50 ring-1 ring-primary-600'
                  : 'border-gray-200 bg-white',
                'relative flex cursor-pointer select-none rounded-xl border p-4 focus:outline-none ui-disabled:cursor-not-allowed ui-disabled:bg-gray-50',
                optionContainerClassName
              )
            }
          >
            {({ focus, checked }) => (
              <>
                <span
                  className={twMerge(
                    checked
                      ? 'border-transparent bg-primary-600'
                      : 'border-gray-300 bg-white',
                    focus ? 'ring-2 ring-primary-600 ring-offset-2' : '',
                    option.disabled
                      ? 'bg-opcaity-50 cursor-not-allowed'
                      : 'cursor-pointer',
                    'mt-0.5 flex h-4 w-4 shrink-0 items-center justify-center rounded-full border'
                  )}
                  aria-hidden="true"
                >
                  <span className="h-1.5 w-1.5 rounded-full bg-white" />
                </span>
                <div className="ml-3 w-full">
                  <option.Content checked={checked} />
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>

      {error ? <p className="mt-1.5 text-sm text-red-600">{error}</p> : null}
    </RadioGroup>
  );
}

function RadioBox(props: RadioBoxProps) {
  const { name, disabled, ...restProps } = props;
  const {
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useFormContext();

  const error = get(errors, name);

  const handleChange = (value: string) => {
    setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const value = watch(name);
  return (
    <RadioBoxBase
      onChange={handleChange}
      error={error?.message?.toString()}
      disabled={disabled || isSubmitting}
      value={value || undefined}
      {...restProps}
    />
  );
}

export { RadioBox, RadioBoxBase };
export type { RadioBoxBaseProps, RadioBoxOption, RadioBoxOptionProps };
