import { Alert } from '@/components/alert';
import { DeleteResource } from '@/components/delete-resource';
import { useSchoolYear } from '@/context/school-year.context';
import {
  LocationResourceFormActionTypes,
  useLocationResourceFormReducer,
} from '@/reducers/resources.reducer';
import { HttpError, isBadRequest } from '@/types/error';
import {
  getAllocatedCapacityRangeForAllDay,
  transformInitialLocationResourceValue,
  transformLocationResourceFormDataToDto,
} from '@/utils/location-resource-utils';
import { generateClassByCapacity } from '@/utils/location-utils';
import {
  CreateResourceDto,
  RatioCapacity,
  Resource,
  SessionType,
  UpdateResourceDto,
} from '@admissions-support/types';
import * as Sentry from '@sentry/react';
import { Plus, Trash01 } from '@untitled-ui/icons-react';
import { addDays, format, isSameDay, isValid } from 'date-fns';
import { capitalize, isEqual, isNumber, uniq } from 'lodash-es';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { MultiSelectBase } from '../../form/common/multi-select';
import { TextInputBase } from '../../form/common/text-input';
import { Toggle } from '../../form/common/toggle';

type LocationResourceFormProps =
  // Create
  | {
      sessionTypes: SessionType[];
      ratioCapacities: RatioCapacity[];
      onSubmit: (data: CreateResourceDto) => Promise<Resource>;
      onClose: () => void;
      isMutating?: boolean;
      initialData?: undefined;
      id?: string;
      operationId?: string;
    }
  // Update
  | {
      id: string;
      operationId: string;
      sessionTypes: SessionType[];
      ratioCapacities: RatioCapacity[];
      onSubmit: (data: UpdateResourceDto) => Promise<Resource>;
      initialData: Resource;
      onClose: () => void;
      isMutating?: boolean;
    };

function LocationResourceForm(props: LocationResourceFormProps) {
  const {
    sessionTypes,
    ratioCapacities,
    initialData,
    onClose,
    isMutating,
    onSubmit,
    operationId,
    id,
  } = props;

  const transformedInitialState = transformInitialLocationResourceValue(
    ratioCapacities,
    initialData
  );

  const [state, dispatch] = useLocationResourceFormReducer(
    ratioCapacities,
    sessionTypes,
    transformedInitialState
  );

  const rootRef = useRef<HTMLFormElement>(null);
  const [capacityError, setCapacityError] = useState<string[]>([]);
  const { schoolYear } = useSchoolYear();

  // // if no initial data passed we are creating a new resource
  const isCreateNewResourceComponent = initialData === undefined;

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const payload = transformLocationResourceFormDataToDto(
        state,
        isCreateNewResourceComponent
      );

      await onSubmit(payload);
      onClose();
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        if (Array.isArray(httpError.message)) {
          const capacityErrors = httpError.message
            //if errors are prefixed with extraHours.capacities, we know the error is about the capacity numbers
            .filter(msg => msg.includes('extrahours.capacities'))
            .map(msg => msg.replace('extrahours.capacities ', ''));
          setCapacityError(capacityErrors);
        }
      }

      if (!isBadRequest(httpError)) {
        Sentry.captureException(error);
      }
    }
  };

  const selectedSessionType = sessionTypes.find(
    sessionType => sessionType.id === state.sessionTypeIds[0]
  );

  // you only can select session type if they work on the same day as the others
  const selectableSessionTypes = sessionTypes.filter(sessionType => {
    // no session type is selected, so you may choose any of it
    if (!selectedSessionType) {
      return true;
    }

    if (isEqual(sessionType.operatingDays, selectedSessionType.operatingDays)) {
      return true;
    }

    return false;
  });

  useEffect(() => {
    // When the component mounts, scroll the page to this component
    if (rootRef.current) {
      rootRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);

  return (
    <>
      {/* Display blur on edit */}
      <div
        className="fixed inset-0 z-10 !mt-0 backdrop-blur-sm transition-opacity"
        role="presentation"
        onClick={onClose}
      />
      <form
        className="white-container group relative z-10 pt-0"
        ref={rootRef}
        onSubmit={handleSubmit}
      >
        <div className="sticky left-0 top-14 z-50 flex items-center justify-between bg-white py-4 lg:top-0">
          <p className="text-md my-1.5 font-medium">
            {isCreateNewResourceComponent ? 'Add Resource' : 'Edit Resource'}
          </p>

          <div className="flex space-x-3">
            {isCreateNewResourceComponent ? (
              <button
                type="button"
                className="btn btn-secondary py-2"
                onClick={onClose}
                disabled={isMutating}
              >
                Cancel
              </button>
            ) : (
              id &&
              operationId && (
                <DeleteResource
                  operationId={operationId}
                  resourceId={id}
                  onSuccess={onClose}
                />
              )
            )}
            <button
              className={'btn btn-primary py-2'}
              disabled={isMutating || state.sessionTypeIds.length < 1}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
        <div className="mb-4 space-y-6">
          <MultiSelectBase
            name="sessionTypeIds"
            label="Session Type(s)"
            helperText="Select session types that will use these spaces."
            placeholder="Select Session..."
            options={selectableSessionTypes.map(sessionType => ({
              label: sessionType.name,
              value: sessionType.id,
            }))}
            isDisabled={isMutating}
            value={state.sessionTypeIds}
            isOptionDisabled={() => state.sessionTypeIds.length >= 1}
            onChange={value =>
              dispatch({
                type: LocationResourceFormActionTypes.SET_SESSSION_TYPES,
                payload: {
                  sessionTypeIds: value,
                  defaultStartDate: format(schoolYear.startDate, 'yyyy-MM-dd'),
                },
              })
            }
          />
          {capacityError.length > 0 && (
            <Alert
              text={
                <div className="space-y-2">
                  {capacityError.map(error => (
                    <p key={error} className="leading-tight">
                      {error}
                    </p>
                  ))}
                </div>
              }
              type="error"
            />
          )}
          {state.resources.map((resource, index) => {
            return (
              <div className="light-gray-container" key={resource.uid}>
                <div className="flex items-center justify-between">
                  <p className="text-md mb-2 font-semibold text-gray-700">
                    {index === 0 ? 'Initial' : 'Scheduled Change'}
                  </p>
                  {index !== 0 && (
                    <button
                      className="btn btn-error ml-auto flex items-center"
                      onClick={() => {
                        dispatch({
                          type: LocationResourceFormActionTypes.REMOVE_RESOURCE_GROUP,
                          payload: {
                            uid: resource.uid,
                          },
                        });
                      }}
                      type="button"
                    >
                      <Trash01
                        className="mr-2 h-5 w-5"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      />
                      Remove
                    </button>
                  )}
                </div>

                <div className="my-4 space-y-4">
                  <TextInputBase
                    type="date"
                    label="Date From"
                    min={format(addDays(new Date(), 1), 'yyyy-MM-dd')}
                    value={
                      isValid(new Date(resource.from))
                        ? format(resource.from, 'yyyy-MM-dd')
                        : ''
                    }
                    disabled={isMutating || index === 0}
                    onChange={e => {
                      dispatch({
                        type: LocationResourceFormActionTypes.SET_SCHEDULED_FROM_DATE,
                        payload: {
                          uid: resource.uid,
                          newDate: format(e.target.value, 'yyyy-MM-dd'),
                        },
                      });
                    }}
                  />
                </div>

                <div className="space-y-2">
                  {resource.resourceGroups.map(resGroup => {
                    const ratioName =
                      state.ratioCapacities.find(
                        rc => rc.ratio.id.toString() === resGroup.ratioId
                      )?.ratio.name || '';
                    const isAllCapacityTheSame =
                      uniq(resGroup.capacityGroup.map(cg => cg.capacity))
                        .length === 1;

                    let isAllocatedRangeFlat;

                    let isCapacityRangeFlat;

                    let allocatedContent;

                    let capacityContent;

                    let rangeForAllDay;

                    if (resGroup.hasSameCapacityForEachDay) {
                      rangeForAllDay = getAllocatedCapacityRangeForAllDay(
                        resGroup.ratioId,
                        resource.from,
                        initialData
                      );

                      isAllocatedRangeFlat =
                        rangeForAllDay?.allocated.min ===
                        rangeForAllDay?.allocated.max;

                      isCapacityRangeFlat =
                        rangeForAllDay?.capacity.min ===
                        rangeForAllDay?.capacity.max;

                      allocatedContent = isAllocatedRangeFlat
                        ? rangeForAllDay?.allocated.max
                        : `${rangeForAllDay?.allocated.min}-${rangeForAllDay?.allocated.max}`;

                      capacityContent = isCapacityRangeFlat
                        ? rangeForAllDay?.capacity.max
                        : `${rangeForAllDay?.capacity.min}-${rangeForAllDay?.capacity.max}`;
                    }

                    return (
                      <div
                        key={`${resource.from}-${resGroup.ratioId}`}
                        className="white-container"
                      >
                        <p className="text-md mb-4 font-semibold text-gray-700">
                          {ratioName}
                        </p>
                        <Toggle
                          value={resGroup.hasSameCapacityForEachDay}
                          onChange={() =>
                            dispatch({
                              type: LocationResourceFormActionTypes.TOGGLE_SAME_CAPACITY,
                              payload: {
                                ratioId: resGroup.ratioId,
                                uid: resource.uid,
                              },
                            })
                          }
                          label="Same Resource for each day"
                          disabled={!selectedSessionType}
                          description="Every day will have the same level of resource available"
                        />
                        <div className="dark-gray-container mt-4">
                          {resGroup.hasSameCapacityForEachDay ? (
                            <div>
                              <div className="flex items-center justify-between">
                                <p className="mb-2 text-sm font-medium text-gray-700">
                                  All Days
                                </p>
                                {rangeForAllDay && (
                                  <span
                                    className={twMerge(
                                      generateClassByCapacity(
                                        rangeForAllDay.capacity.max || 0,
                                        rangeForAllDay.allocated.max || 0
                                      ),
                                      'mb-1 hover:cursor-pointer'
                                    )}
                                    data-tooltip-content={`${allocatedContent} Allocated of ${capacityContent}`}
                                    data-tooltip-id="capacity-tooltip"
                                  >
                                    {allocatedContent}
                                  </span>
                                )}
                              </div>
                              <TextInputBase
                                value={
                                  isAllCapacityTheSame
                                    ? isNumber(
                                        resGroup.capacityGroup[0].capacity
                                      )
                                      ? resGroup.capacityGroup[0].capacity
                                      : ''
                                    : 0
                                }
                                disabled={isMutating}
                                onChange={e => {
                                  dispatch({
                                    type: LocationResourceFormActionTypes.SET_GENERAL_CAPACITY,
                                    payload: {
                                      ratioId: resGroup.ratioId,
                                      uid: resource.uid,
                                      capacity: parseInt(e.target.value),
                                    },
                                  });
                                }}
                                min={0}
                                type="number"
                              />
                            </div>
                          ) : (
                            <div className="grid grid-cols-2 gap-6">
                              {resGroup.capacityGroup.map((capDay, idx) => {
                                const currentDbCapacityObject =
                                  initialData?.capacities.find(c =>
                                    isSameDay(
                                      new Date(c.from),
                                      new Date(resource.from)
                                    )
                                  );

                                const currentDay = currentDbCapacityObject
                                  ? currentDbCapacityObject[capDay.day].find(
                                      c =>
                                        c.ratio.id.toString() ===
                                        resGroup.ratioId
                                    )
                                  : null;

                                const allocated = currentDay?.allocated || 0;
                                const capacity = currentDay?.capacity || 0;
                                return (
                                  <div
                                    key={`${resource.from}-${resGroup.ratioId}-${capDay.day}-${idx}`}
                                  >
                                    <div className="flex items-center justify-between">
                                      <p className="mb-2 text-sm font-medium text-gray-700">
                                        {capitalize(capDay.day)}
                                      </p>
                                      <span
                                        className={twMerge(
                                          generateClassByCapacity(
                                            capacity || 0,
                                            allocated || 0
                                          ),
                                          'mb-1 hover:cursor-pointer'
                                        )}
                                        data-tooltip-content={`${allocated} Allocated of ${capacity}`}
                                        data-tooltip-id="capacity-tooltip"
                                      >
                                        {allocated}
                                      </span>
                                    </div>
                                    <TextInputBase
                                      onChange={e =>
                                        dispatch({
                                          type: LocationResourceFormActionTypes.SET_CAPACITY,
                                          payload: {
                                            ratioId: resGroup.ratioId,
                                            capacity: parseInt(e.target.value),
                                            day: capDay.day,
                                            uid: resource.uid,
                                          },
                                        })
                                      }
                                      value={
                                        isNumber(capDay.capacity)
                                          ? capDay.capacity
                                          : ''
                                      }
                                      min={0}
                                      type="number"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        {state.sessionTypeIds.length > 0 && (
          <button
            type="button"
            className="btn btn-secondary mt-2 flex w-full items-center justify-center"
            onClick={() => {
              dispatch({
                type: LocationResourceFormActionTypes.ADD_RESOURCE_GROUP,
              });
            }}
          >
            <Plus
              aria-hidden="true"
              className="mr-2 h-5 w-5"
              viewBox="0 0 24 24"
            />
            Schedule Change
          </button>
        )}
      </form>
    </>
  );
}

export { LocationResourceForm };
