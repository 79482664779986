import { Alert } from '@/components/alert';
import { TextInput } from '@/components/form/common/text-input';
import { Toggle } from '@/components/form/common/toggle';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useUpdateApplicationMutation } from '@/hooks/update-hooks/use-update-application-mutation';
import { Permission } from '@/types/auth';
import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { StartDate } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { format, isSameDay } from 'date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Detail } from './detail';

type UpdateStartDateFormProps = {
  applicationId: string;
  startDate: StartDate;
};

type UpdateStartDateFormData = yup.InferType<typeof applicationStartDateSchema>;

const applicationStartDateSchema = yup.object({
  isOverrideOriginalStartDate: yup
    .boolean()
    .label('Override Start Date?')
    .required(),
  overrideDate: yup
    .string()
    .label('Start Date')
    .when('isOverrideOriginalStartDate', {
      is: true,
      then: schema => schema.required(),
      otherwise: schema => schema,
    }),
});

function transformApiDataToFormData(
  initialData: StartDate
): UpdateStartDateFormData {
  if (!initialData) {
    return {
      isOverrideOriginalStartDate: false,
      overrideDate: '',
    };
  }
  return {
    isOverrideOriginalStartDate: !isSameDay(
      new Date(initialData.override),
      new Date(initialData.original)
    ),
    overrideDate: format(new Date(initialData.override), 'yyyy-MM-dd'),
  };
}

function UpdateStartDateForm(props: UpdateStartDateFormProps) {
  const { startDate, applicationId } = props;
  const { hasPermission } = usePermissions();

  const defaultValues: UpdateStartDateFormData =
    transformApiDataToFormData(startDate);

  const form = useForm({
    resolver: yupResolver(applicationStartDateSchema),
    defaultValues,
  });

  const { mutateAsync: updateApplication } =
    useUpdateApplicationMutation(applicationId);

  const handleSubmit = async (values: UpdateStartDateFormData) => {
    try {
      // if no overrideDate is defined we assume child starts at the originally calculated date
      const response = await updateApplication({
        startDate: values.overrideDate || startDate.original.toString(),
      });
      form.reset(transformApiDataToFormData(response.startDate));
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        const availableFields = getNestedKeys(form.getValues());

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }

      form.setError('root.server', {
        message: Array.isArray(httpError.message)
          ? httpError.message[0]
          : httpError.message,
      });
    }
  };

  const isOverrideOriginalStartDate = form.watch('isOverrideOriginalStartDate');

  if (!startDate) {
    return <Alert type="error" text="Start Date cannot be calculated!" />;
  }

  return (
    <div>
      <Detail
        label="Eligible From"
        content={
          startDate.original
            ? format(new Date(startDate.original), 'dd/MM/yyyy')
            : '-'
        }
      />
      {defaultValues.isOverrideOriginalStartDate && (
        <Detail
          label="Start From"
          content={
            defaultValues.overrideDate
              ? format(new Date(defaultValues.overrideDate), 'dd/MM/yyyy')
              : '-'
          }
        />
      )}
      {hasPermission([Permission['application:update']]) && (
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="mt-2 space-y-4"
          >
            <Controller
              name="isOverrideOriginalStartDate"
              control={form.control}
              render={({ field: { onChange, value, disabled } }) => {
                return (
                  <Toggle
                    value={value}
                    onChange={isChecked => {
                      form.resetField('overrideDate', {
                        defaultValue: '',
                        keepDirty: true,
                        keepTouched: true,
                      });
                      onChange(isChecked);
                    }}
                    label="Override Start Date"
                    disabled={disabled}
                    description="Setting a date here will override their default eligibility."
                  />
                );
              }}
            />
            {isOverrideOriginalStartDate ? (
              <TextInput name="overrideDate" type="date" label="Start Date" />
            ) : null}
            {form.formState.isDirty ? (
              <button className="btn btn-primary">Save</button>
            ) : null}
          </form>
        </FormProvider>
      )}
    </div>
  );
}

export { UpdateStartDateForm };
