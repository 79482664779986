import { ChoiceForm } from '@/components/form/choice.form';
import { ScrollableModal } from '@/components/scroll-modal';
import { useChoicesContext } from '@/context/choices.context';
import {
  selectNbOfSelectedSessions,
  selectNbOfSelectedSplitPlacementSessions,
} from '@/utils/application-utils';
import {
  isCouncilChoice,
  transformChoiceStateToChoiceDto,
} from '@/utils/choice-utils';
import {
  Choice,
  CreateChoiceDto,
  Location,
  Model,
  SessionType,
  UpdateChoiceDto,
} from '@admissions-support/types';
import { twMerge } from 'tailwind-merge';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactElement;
};

type ChoiceUpdateProps = {
  onSubmit: (data: UpdateChoiceDto) => Promise<any>;
  initialData: Choice;
  isMutating?: boolean;
  locations: Location[];
  models: Model[];
  sessionTypes: SessionType[];
  schoolYearId: string;
} & ModalProps;

type ChoiceCreateProps = {
  onSubmit: (data: CreateChoiceDto) => Promise<any>;
  nbPreference: number;
  initialData?: undefined;
  isMutating?: boolean;
  locations: Location[];
  models: Model[];
  sessionTypes: SessionType[];
} & ModalProps;

type ChoiceModalProps = ChoiceCreateProps | ChoiceUpdateProps;

function isUpdateVersion(props: ChoiceModalProps): props is ChoiceUpdateProps {
  return props.initialData !== undefined;
}

function ChoiceModal(props: ChoiceModalProps) {
  const {
    onClose,
    open,
    initialData,
    sessionTypes,
    isMutating,
    models,
    locations,
  } = props;

  const isCreateNewChoiceComponent = initialData === undefined;

  const { state } = useChoicesContext();

  const selectedSessionType = sessionTypes.find(
    type => type.id === state.sessionType
  );

  const handleSubmit = async () => {
    // This shouldn't happen, guarding just in case and for correct inference
    if (!selectedSessionType) {
      console.error(
        `Selected Session Type { id: '${state.sessionType}' } could not be found in data`
      );
      return;
    }

    const choice = transformChoiceStateToChoiceDto(
      state,
      isUpdateVersion(props)
        ? // eslint-disable-next-line react/destructuring-assignment
          props.initialData.nbPreference
        : // eslint-disable-next-line react/destructuring-assignment
          props.nbPreference,
      selectedSessionType
    );

    if (isUpdateVersion(props)) {
      // typeguard does not work if its destructed
      // eslint-disable-next-line react/destructuring-assignment
      await props.onSubmit(choice);
    } else {
      const choiceDto: CreateChoiceDto = {
        choices: [
          {
            ...choice,
            // eslint-disable-next-line react/destructuring-assignment
            type: props.nbPreference < 0 ? 'PRESCRIBED' : 'USER_DEFINED',
          },
        ],
      };
      // eslint-disable-next-line react/destructuring-assignment
      await props.onSubmit(choiceDto);
    }

    onClose();
  };

  function shouldSubmitButtonBeDisabled() {
    // Form is submitting
    if (isMutating) {
      return true;
    }

    if (!isCouncilChoice(state) && state.establishment !== '') {
      return false;
    }

    // User did not select session type yet
    if (!state.sessionType) {
      return true;
    }

    // User did not select any session yet
    if (selectNbOfSelectedSessions(state) === 0) {
      return true;
    }

    if (
      selectNbOfSelectedSessions(state) -
        selectNbOfSelectedSplitPlacementSessions(state) ===
      0
    ) {
      return true;
    }

    /**
     * If split placement is enabled at the session type and
     * there is a minimum day child should spend at the selected location.
     * Check if user selected at least the required number of sessions
     * at the split location
     */
    if (
      state.enableSplitPlacement &&
      selectedSessionType &&
      selectedSessionType.splitPlacement &&
      selectedSessionType.splitPlacement.hasMinimumDaysAtLocation
    ) {
      return (
        selectNbOfSelectedSplitPlacementSessions(state) <
        selectedSessionType.splitPlacement.minimumDaysAtLocation
      );
    }
  }

  const isSubmitButtonDisabled = shouldSubmitButtonBeDisabled();

  return (
    <ScrollableModal onClose={onClose} isOpen={open} size="2xl">
      <ScrollableModal.Content className="mt-6">
        <ChoiceForm
          isMutating={isMutating}
          sessionTypes={sessionTypes}
          models={models}
          locations={locations}
        />
      </ScrollableModal.Content>
      <ScrollableModal.Footer>
        <div
          className={twMerge(
            'flex w-full items-center justify-between bg-white'
          )}
        >
          <p className="text-md my-1.5 font-medium">
            {isUpdateVersion(props)
              ? // eslint-disable-next-line react/destructuring-assignment
                `${props.initialData.nbPreference}. Choice`
              : // eslint-disable-next-line react/destructuring-assignment
              props.nbPreference < 0
              ? 'Prescribed Choice'
              : // eslint-disable-next-line react/destructuring-assignment
                `${props.nbPreference}. Choice`}
          </p>
          <div className="space-x-3">
            <button
              type="button"
              className="btn btn-secondary py-2"
              onClick={onClose}
              disabled={isMutating}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary py-2"
              disabled={isSubmitButtonDisabled}
              onClick={handleSubmit}
            >
              {isCreateNewChoiceComponent ? 'Create' : 'Save'}
            </button>
          </div>
        </div>
      </ScrollableModal.Footer>
    </ScrollableModal>
  );
}

export { ChoiceModal };
