const getEnvPrefixFromEnv = () => {
  const env = import.meta.env.VITE_ENVIRONMENT as
    | 'local'
    | 'development'
    | 'staging'
    | 'production';

  switch (env) {
    case 'local':
      return 'dev';
    case 'development':
      return 'dev';
    case 'staging':
      return 'stag';
    case 'production':
      return 'prod';
  }
};

export { getEnvPrefixFromEnv };
