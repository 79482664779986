import * as Sentry from '@sentry/react';
import {
  FaceContent,
  LogOut02,
  MessageCircle02,
} from '@untitled-ui/icons-react';
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';

import { FallbackComponent } from '@/components/fallback-component';
import { LoadingScreen } from '@/components/loading-screen';
import { Logo } from '@/components/logo';
import { parentRouterPath } from '@/config/route-paths.config';
import { useAuth } from '@/context/auth.context';
import { useChildren } from '@/hooks/query-hooks/use-children';
import { isProd } from '@/utils/env-utils';
import { twMerge } from 'tailwind-merge';
import { CookieBanner } from '@/components/cookie-banner';
import { MobileNavigation } from '@/components/mobile-navigation';
import { NavigationItem } from '@/types/navigation';

function ParentsChildrenListLayout() {
  const { logOut } = useAuth();
  const {
    data: children,
    isError,
    isSuccess,
    isLoading,
    isFetching,
  } = useChildren();
  const location = useLocation();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError || !isSuccess) {
    return <FallbackComponent />;
  }

  // If no children redirect to onboarding
  if (children.length === 0 && !isFetching && !isProd()) {
    return <Navigate to={parentRouterPath.WELCOME} />;
  }

  const navigation: NavigationItem[] = [
    {
      name: 'Children',
      href: parentRouterPath.ROOT,
      icon: FaceContent,
    },
    {
      name: 'Help Desk',
      href: parentRouterPath.MESSAGING,
      icon: MessageCircle02,
    },
  ];

  return (
    <>
      <div className="h-full bg-gray-50">
        <header className="hidden bg-white py-3 shadow-sm lg:block">
          <div className="mx-auto flex items-center justify-between px-4 xl:container">
            <div className="flex items-center gap-x-8">
              <Logo className="h-12" />
              <div className="flex items-center gap-x-1">
                <Link
                  to={parentRouterPath.ROOT}
                  className={twMerge(
                    'btn btn-empty btn-icon text-gray-700 ',
                    location.pathname === parentRouterPath.ROOT
                      ? 'bg-blue-200'
                      : 'hover:text-gray-900'
                  )}
                >
                  Children
                </Link>
                <Link
                  to={parentRouterPath.MESSAGING}
                  className={twMerge(
                    'btn btn-empty btn-icon text-gray-700 hover:text-gray-900',
                    location.pathname === parentRouterPath.MESSAGING
                      ? 'bg-blue-200'
                      : 'hover:text-gray-900'
                  )}
                >
                  Help Desk
                </Link>
              </div>
            </div>
            <div className="flex items-center justify-between gap-x-3">
              {/* <LifeBuoy02 className="h-5 text-gray-500" aria-hidden="true" /> */}
              {/* <Settings01 className="h-5 text-gray-500" aria-hidden="true" /> */}
              <button className="btn btn-empty btn-icon" onClick={logOut}>
                <LogOut02
                  className="mr-2 h-5 w-5 text-gray-500"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                />
                Log out
              </button>
            </div>
          </div>
        </header>
        <MobileNavigation navigation={navigation} bottomNavigation={[]} />
        <div className="h-[52px] lg:hidden" />
        <main className="mt-8 max-w-full px-4 xl:container">
          <Sentry.ErrorBoundary
            fallback={props => <FallbackComponent {...props} />}
            key={location.pathname}
            showDialog
          >
            <Outlet />
          </Sentry.ErrorBoundary>
        </main>
      </div>

      <CookieBanner />
    </>
  );
}

export { ParentsChildrenListLayout };
