import Select, { GroupBase, Props } from 'react-select';
import { twMerge } from 'tailwind-merge';

function ValueContainer({ value, badge }: Option) {
  return (
    <div className="z-0 flex w-full justify-between">
      <p>{value}</p>
      {badge}
    </div>
  );
}

type Option = { key: any; value: string; badge?: React.ReactNode };

interface CustomisableSelectProps
  extends Props<Option, false, GroupBase<Option>> {
  name: string;
  options: Option[];
  label?: string;
  helperText?: string;
  menuListClasses?: string;
  onlyDropdown?: boolean;
  dropdownWidth?: number;
}

function CustomSelect({
  label,
  name,
  options,
  value,
  onChange,
  menuListClasses,
  onlyDropdown,
  dropdownWidth,
  ...inputProps
}: Omit<CustomisableSelectProps, 'value' | 'onChange'> & {
  value: string | null;
  error?: string;
  onChange: (data: any) => void;
}) {
  return (
    <div>
      {label ? (
        <label htmlFor={name} className="label">
          {label}
        </label>
      ) : null}
      <div className={twMerge('relative', label ? 'mt-2' : null)}>
        <Select
          options={options}
          isClearable={false}
          closeMenuOnSelect={true}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: base => ({
              ...base,
              width: dropdownWidth || base.width,
              zIndex: 9999,
            }),
            input: baseStyle => ({
              ...baseStyle,
              display: onlyDropdown ? 'none' : baseStyle.display,
              padding: 0,
              margin: 0,
              'input:focus': {
                boxShadow: 'none',
              },
            }),
            valueContainer: baseStyle => ({
              ...baseStyle,
              display: onlyDropdown ? 'none' : baseStyle.display,
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 12,
            }),
            dropdownIndicator: baseStyle => ({
              display: onlyDropdown ? 'none' : baseStyle.display,
            }),
            /*
             * with empty return objects no default style will be added by the lib,
             * and can be styled with tailwind classes in classNames property
             */
            control: () => ({}),
            option: () => ({}),
          }}
          classNames={{
            /**
             * it is recommended to cap the menuList height in modals
             * every class should be added with !
             */
            menuList: () => (menuListClasses ? menuListClasses : ''),
            singleValue: () => 'hover:cursor-default',
            control: state =>
              twMerge(
                'flex bg-white',
                onlyDropdown ? '' : 'pt-[8px] pb-[8px] input',
                state.isDisabled ? 'bg-gray-50 text-gray-500' : null,
                state.isFocused ? 'ring-2 ring-inset ring-primary-600' : null
              ),
            placeholder: () => 'my-[1px]',
            dropdownIndicator: () => '!py-0 !text-gray-500',
            clearIndicator: () => '!py-0 cursor-pointer',
            option: state =>
              twMerge(
                'px-3 py-2 select-none cursor-pointer hover:bg-secondary-light-200 hover:text-primary-800 !z-50',
                state.isFocused
                  ? 'bg-secondary-light-200 text-primary-800'
                  : null
              ),
          }}
          formatOptionLabel={ValueContainer}
          // this hides the dropdown separator
          components={{
            IndicatorSeparator: () => null,
          }}
          value={options.find(option => option.key === value)}
          onChange={val => onChange(val?.key || '')}
          {...inputProps}
        />
      </div>
    </div>
  );
}

export { CustomSelect };
