import { Alert } from '@/components/alert';
import { ApplicationTemplateChoices } from '@/components/application/eligibility/application-template-choices';
import { FallbackComponent } from '@/components/fallback-component';
import { TextInputBase } from '@/components/form/common/text-input';
import { LoadingScreen } from '@/components/loading-screen';
import { useChild } from '@/hooks/query-hooks/use-child';
import { useEligibility } from '@/hooks/query-hooks/use-eligibility';
import { useTerms } from '@/hooks/query-hooks/use-terms';
import {
  formatDateToShortDate,
  formatDateToYYYYMMDD,
} from '@/utils/format-date';
import { Calendar } from '@untitled-ui/icons-react';
import { isFuture, isWithinInterval } from 'date-fns';
import { ChangeEvent, useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

type EligibilityFormProps = {
  childId: string;
};
function EligibilityForm({ childId }: EligibilityFormProps) {
  const [searchParams, setSearchParams] = useSearchParams({
    schoolYearId: '',
  });
  const {
    data: child,
    isLoading: isChildLoading,
    isError: isChildError,
    isSuccess: childIsSuccess,
  } = useChild(childId || '');

  const [dateOfBirth, setDateOfBirth] = useState('');
  const [schoolYearId, setSchoolYearId] = useState<string>(
    searchParams.get('schoolYearId') || ''
  );

  useEffect(() => {
    if (schoolYearId) {
      setSearchParams({ schoolYearId: schoolYearId });
    }
  }, [schoolYearId, setSearchParams]);

  const {
    data: eligibility,
    isSuccess: isEligibilitySuccess,
    isLoading,
    isError: isEligibility,
    error: eligibilityError,
    refetch,
  } = useEligibility(
    { birthday: dateOfBirth, schoolYearId },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    data: schoolYears,
    isLoading: isSchoolYearsLoading,
    isError: isSchoolYearError,
    isSuccess: isSchoolYearSuccess,
  } = useTerms();

  useEffect(() => {
    if (!childIsSuccess) {
      return;
    }

    setDateOfBirth(formatDateToYYYYMMDD(child.dateOfBirth));
  }, [childIsSuccess, child]);

  if (isChildLoading || isSchoolYearsLoading) {
    return <LoadingScreen />;
  }

  if (isChildError || isSchoolYearError) {
    return <Navigate to="/404" />;
  }

  if (!childIsSuccess || !isSchoolYearSuccess) {
    return <FallbackComponent />;
  }

  const handleEligibilityCheck = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    await refetch();
  };

  /**
   * display future school-years or the current one
   */
  const schoolYearsToDisplay = schoolYears.filter(
    schoolYear =>
      isFuture(new Date(schoolYear.endDate)) ||
      isWithinInterval(new Date(), {
        start: new Date(schoolYear.startDate),
        end: new Date(schoolYear.endDate),
      })
  );

  return (
    <>
      <form className="mb-4 space-y-4" onSubmit={handleEligibilityCheck}>
        <fieldset className="mt-6">
          <label className="label" htmlFor="school-year">
            School Year
          </label>
          <select
            id="school-year"
            className="input shadow-none"
            onChange={e => setSchoolYearId(e.target.value)}
            value={schoolYearId}
          >
            <option>Choose School Year...</option>
            {schoolYearsToDisplay.map(term => (
              <option key={term.id} value={term.id}>
                {term.name}
              </option>
            ))}
          </select>
        </fieldset>
        <div className=" flex items-end space-x-2">
          <TextInputBase
            name="dob"
            type="date"
            label="Child`s Date of Birth"
            value={dateOfBirth}
            onChange={event => setDateOfBirth(event.target.value)}
            disabled
          />
          <button
            className="btn btn-secondary"
            type="submit"
            disabled={!dateOfBirth || !schoolYearId}
          >
            Check Eligibility
          </button>
        </div>
      </form>

      {isLoading && (
        <div className="light-gray-container">
          <LoadingScreen />
        </div>
      )}

      {isEligibility && (
        <Alert
          type="error"
          text={
            Array.isArray(eligibilityError.message)
              ? eligibilityError.message.map((message, index) => (
                  <p key={`error-${index}`}>{message}</p>
                ))
              : eligibilityError.message
          }
        />
      )}

      {isEligibilitySuccess && (
        <>
          <div className="light-gray-container mb-4 flex space-x-2">
            <div>
              <div className="self-start rounded-lg border border-gray-200 p-2">
                <Calendar />
              </div>
            </div>
            <div>
              <p className="text-md mb-2 font-semibold text-gray-900">
                Eligible From
              </p>
              <p>
                Based on your child`s DOB you can apply for childcare from{' '}
                <b>{formatDateToShortDate(eligibility.intake.start)}</b>.
                {eligibility.intake.name !== 'school-year-intake' && (
                  <>
                    The deadline for this intake is{' '}
                    <b>{formatDateToShortDate(eligibility.intake.deadline)}</b>
                  </>
                )}
              </p>
            </div>
          </div>
          <ApplicationTemplateChoices
            cohortId={eligibility.cohort.id}
            childIdentificationNumber={child.identificationNumber}
            schoolYearId={schoolYearId}
          />
        </>
      )}
    </>
  );
}

export { EligibilityForm };
