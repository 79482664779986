import { useAddresses } from '@/hooks/query-hooks/use-addresses';

import { useFormContext } from 'react-hook-form';

import { useEffect, useRef, useState } from 'react';
import { useOutsideClick } from '@/hooks/use-outside-click';
import { compact } from 'lodash-es';
import { SeemisAddress } from '@admissions-support/types';
import { CustomSelect } from '../custom-select';
import { TextInput } from '../form/common/text-input';
import { Loading01 } from '@untitled-ui/icons-react';

type AddressProps = {
  fieldNamePrefix?: string;
  disabled?: boolean;
};

function Address(props: AddressProps) {
  const { disabled, fieldNamePrefix } = props;

  const outsideClickRef = useRef<HTMLDivElement | null>(null);

  const form = useFormContext();

  const postCode = form.watch(`${fieldNamePrefix || ''}address.postcode`);

  const shouldShowAddressFields = Boolean(
    form.watch(`${fieldNamePrefix || ''}address.northings`) ||
      form.watch(`${fieldNamePrefix || ''}address.eastings`) ||
      form.watch(`${fieldNamePrefix || ''}address.uprn`)
  );

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const {
    data: addresses,
    isLoading,
    isError,
    refetch,
  } = useAddresses({ postCode }, { enabled: false });

  useOutsideClick(outsideClickRef, e => {
    const realTarget = e.target as HTMLDivElement;
    if (realTarget.offsetParent?.id.includes('react-select')) {
      return;
    }
    setIsDropdownOpen(false);
  });

  const addressOptions = addresses?.map(address => ({
    value: compact([
      address.lineOne,
      address.lineTwo,
      address.lineThree,
      address.postcode,
    ]).join(', '),
    key: address,
  }));

  const onSelectAddress = (address: SeemisAddress) => {
    form.setValue(`${fieldNamePrefix || ''}address`, address);
    setIsDropdownOpen(false);
  };

  const dropdownWidth = document.getElementById(
    `${fieldNamePrefix || ''}address.postcode`
  )?.clientWidth;

  useEffect(() => {
    if (isError) {
      form.setError(`${fieldNamePrefix || ''}address.postcode`, {
        message:
          'Failed to lookup address. Please verify your postcode and try again.',
      });
    }

    return () => {
      form.clearErrors(`${fieldNamePrefix || ''}address.postcode`);
    };
  }, [fieldNamePrefix, form, isError]);

  return (
    <>
      <div className="flex" ref={outsideClickRef}>
        <div className="flex flex-1 flex-row">
          <div className="mt-16" data-select-container="true">
            <CustomSelect
              name=""
              value=""
              onlyDropdown
              dropdownWidth={dropdownWidth}
              onChange={onSelectAddress}
              options={addressOptions || []}
              menuIsOpen={isDropdownOpen && !isLoading}
              aria-expanded={isDropdownOpen}
            />
          </div>
          <TextInput
            className="flex-1"
            name={`${fieldNamePrefix || ''}address.postcode`}
            label="Postcode*"
            placeholder="Postcode"
            helperText="Enter your postcode to lookup your address"
            disabled={disabled || isLoading}
          />
        </div>
        <button
          className="btn btn-secondary ml-4 mt-7 flex h-min min-w-[137px] justify-center"
          aria-label="Search for addresses using the provided postcode"
          aria-busy={isLoading}
          type="button"
          onClick={async () => {
            await refetch();
            setIsDropdownOpen(true);
          }}
          disabled={disabled || isLoading}
        >
          {isLoading ? (
            <Loading01
              className="leading-icon h-5 w-5 animate-spin text-black"
              aria-hidden="true"
            />
          ) : (
            'Lookup Address'
          )}
        </button>
      </div>
      {shouldShowAddressFields && (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <TextInput
            type="text"
            name={`${fieldNamePrefix || ''}address.lineOne`}
            label="Address Line 1*"
            placeholder="House/Flat Number and Street"
            disabled={disabled}
          />
          <TextInput
            type="text"
            name={`${fieldNamePrefix || ''}address.lineTwo`}
            label="Address Line 2"
            placeholder="Locality"
            disabled={disabled}
          />
          <TextInput
            type="text"
            name={`${fieldNamePrefix || ''}address.lineThree`}
            label="Town/City"
            placeholder="Post Town"
            disabled={disabled}
          />
        </div>
      )}
    </>
  );
}

export { Address };
