import { activityLogKey } from '@/config/query-keys';
import { activityLogService } from '@/services/activity-log.service';
import { HttpError } from '@/types/error';
import { ActivityLog } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useActivityLogsByChildId(
  childId: string,
  queryOptions?: UseQueryOptions<ActivityLog[], HttpError>
) {
  return useQuery({
    queryKey: activityLogKey.byChildId(childId),
    queryFn: () => activityLogService.getActivityLogByChildId(childId),
    ...queryOptions,
  });
}

function useExportActivityLog(
  childId: string,
  queryOptions?: Partial<UseQueryOptions<Blob, HttpError>>
) {
  return useQuery({
    queryKey: activityLogKey.exportByChildId(childId),
    queryFn: () => {
      return activityLogService.exportActivityLogByChildId(childId);
    },
    ...queryOptions,
  });
}

export { useActivityLogsByChildId, useExportActivityLog };
