import { userKeys } from '@/config/query-keys';
import { userService } from '@/services/user.service';
import { HttpError } from '@/types/error';
import { isAdminDashboard } from '@/utils/is-dashboard';
import { User } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useMe(queryOptions?: Partial<UseQueryOptions<User, HttpError>>) {
  return useQuery({
    queryKey: userKeys.me,
    queryFn: () => userService.getMe(),
    ...queryOptions,
    enabled: isAdminDashboard(),
  });
}

export { useMe };
