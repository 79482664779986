const PaymentOptions: {
  [key: string]: {
    name: string;
    muted?: string;
    description: string;
  };
} = {
  EPAY_CAPITA: {
    name: 'WLC Secure Payments',
    description: 'Pay by debit or credit card',
  },
  TAX_FREE_CHILD_CARE: {
    name: 'Tax Free Childcare',
    description: 'Provide your payment reference number',
  },
  CHILDCARE_VOUCHERS: {
    name: 'Childcare Vouchers',
    description: 'Let us know which scheme you’re using',
  },
  WEST_LOTHIAN_COLLEGE: {
    name: 'West Lothian College',
    description: 'Funding provided',
  },
};

export { PaymentOptions };
