import * as yup from 'yup';

const addressSchema = yup.object({
  lineOne: yup.string().label('Address Line 1').required(),
  lineTwo: yup.string().label('Address Line 2').nullable(),
  lineThree: yup.string().label('Town/City').nullable(),
  lineFour: yup.string().label('').nullable(),
  postcode: yup.string().label('Postcode').required(),
  uprn: yup.string().label('UPRN').nullable(),
  eastings: yup.string().label('Eastings').nullable(),
  northings: yup.string().label('Northings').nullable(),
});

const additionalChildDetailsSchema = yup.object({
  address: addressSchema,
  homeStatus: yup
    .string()
    .label('Current Situation')
    .oneOf(['LIVING', 'MOVING'])
    .required()
    .nullable(),
  moveInDate: yup.string().label('Date of Move').nullable(),
  medicalDetails: yup.object({
    doctorName: yup.string().label('Doctor Name').nullable(),
    healthVisitor: yup.string().label('Health Visitor Name').nullable(),
    surgeryName: yup.string().label('GP Practice Name').nullable(),
    phoneNumber: yup.string().label('Phone Number').nullable(),
    isVisuallyImpaired: yup.boolean().nullable(),
    isHearingImpaired: yup.boolean().nullable(),
    isEpileptic: yup.boolean().nullable(),
    isDiabetic: yup.boolean().nullable(),
    isAllergic: yup.boolean().nullable(),
    isAsmatic: yup.boolean().nullable(),
    isSpeechImpaired: yup.boolean().nullable(),
    allergies: yup.string().label('Allergies').nullable(),
    other: yup.string().label('Other').nullable(),
  }),
  lookedAfter: yup.object({
    isSubjectToCourtDecision: yup.string().label('Court decision').nullable(),
    careLocation: yup.string().label('Care Location').nullable(),
    relationship: yup
      .string()
      .oneOf(['PARENT', 'CARER', 'FOSTER_CARER', 'KINSHIP_CARER', 'OTHER'])
      .label('Relationship')
      .nullable(),
    otherRelationship: yup.string().label('Other Relationship').nullable(),
    authority: yup.string().label('Authority').nullable(),
  }),
  proofOfAddress: yup.array(
    yup.object({
      filename: yup.string().required(),
      reference: yup.string().required(),
      key: yup.string().required(),
    })
  ),
  localAuthorityDetails: yup
    .object({
      nursery: yup.string().label('Nursery').nullable(),
      isSplit: yup.boolean().nullable(),
      splitDetails: yup.string().label('Details').nullable(),
      isAlreadyAttending: yup.boolean().nullable(),
      attendanceDetails: yup.string().label('Details').nullable(),
      isExtendedChildcare: yup.boolean().nullable(),
    })
    .nullable()
    .optional(),
});

export { additionalChildDetailsSchema, addressSchema };
