import { Dialog, Transition } from '@headlessui/react';
import { XClose } from '@untitled-ui/icons-react';
import { ChangeEvent, Fragment, useState } from 'react';
import { Alert } from '../alert';
import { CheckboxBase } from '../form/common/checkbox';

type BookingSummaryProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  isLoading?: boolean;
  nbOfExtraHoursToBook: number;
  daysWithMultipleBooking: string[];
};

function BookingSummary(props: BookingSummaryProps) {
  const {
    isOpen,
    onClose,
    nbOfExtraHoursToBook,
    isLoading,
    onSubmit,
    daysWithMultipleBooking,
  } = props;

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const handleTermsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsTermsAccepted(e.target.checked);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-60" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative mx-10 transform overflow-auto rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-xl">
                <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-5">
                  <div className="pb-5">
                    <Dialog.Title
                      as="h3"
                      className="flex items-center justify-between text-base font-semibold leading-6 text-gray-900"
                    >
                      Confirm Booking
                      <button onClick={onClose} type="button">
                        <XClose className="text-gray-400 hover:text-gray-600" />
                      </button>
                    </Dialog.Title>
                  </div>
                  <p className="mb-4 rounded-lg border border-gray-200 bg-gray-50 p-4 text-sm font-semibold shadow-sm">
                    You are about to book {nbOfExtraHoursToBook} Session
                    {nbOfExtraHoursToBook > 1 ? 's' : ''}
                  </p>
                  <p className="text-md mb-6 text-gray-600">
                    Please note, payments must be made 1 week before the booked
                    session. Sessions can be cancelled up to 4 weeks in advance
                    of the session.
                  </p>
                  {daysWithMultipleBooking.length > 0 && (
                    <Alert
                      type="warning"
                      text={`You can only have 1 extra session per day, but you selected more than one for the following day(s): ${daysWithMultipleBooking.join(
                        ','
                      )}`}
                      className="mb-4"
                    />
                  )}
                  <div className="flex-co mb-8 flex">
                    <CheckboxBase
                      label="I have read and agree to the terms & conditions"
                      helperText={
                        <a
                          target="_blank"
                          href="https://admit.app/BENH.pdf"
                          rel="noreferrer"
                          className="font-normal"
                        >
                          Read the terms & conditions
                        </a>
                      }
                      name="terms"
                      checked={isTermsAccepted}
                      onChange={handleTermsChange}
                    />
                  </div>
                  <div className="flex sm:gap-4">
                    <button
                      onClick={onClose}
                      type="button"
                      className="btn btn-secondary w-full"
                      disabled={isLoading}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={onSubmit}
                      type="button"
                      className="btn btn-primary w-full"
                      disabled={
                        isLoading ||
                        daysWithMultipleBooking.length > 0 ||
                        !isTermsAccepted
                      }
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { BookingSummary };
