import { Select } from '@/components/form/common/select';
import { transformEmptyStringToNullAndNormalizeNumbers } from '@/utils/application-utils';
import { Application, ApplicationTemplate } from '@admissions-support/types';
import { isEqual, omit } from 'lodash-es';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CarersFormData } from './application/carer/carer.type';
import { defaultCarer } from './application/carer/use-carer.form';
import { Checkbox } from './form/common/checkbox';
import { TextInput } from './form/common/text-input';
import { Toggle } from './form/common/toggle';
import { Address } from './application/address.element';

type CarerItemProps = {
  index: number;
  disabled?: boolean;
  initialData: Application;
  template: ApplicationTemplate;
};

const personTitleDisplayText = {
  MR: 'Mr',
  MRS: 'Mrs',
  MX: 'Mx',
  MISS: 'Miss',
  DR: 'Dr',
  MS: 'Ms',
  PROF: 'Prof',
  REV: 'Rev',
};

const personTitleOptions = [
  { key: '', value: 'Select..' },
  ...Object.entries(personTitleDisplayText).map(([key, value]) => ({
    key,
    value,
  })),
];

function CarerItem(props: CarerItemProps) {
  const { control, watch } = useFormContext<CarersFormData>();
  const { index, disabled, initialData, template } = props;

  const { update: updateCarer } = useFieldArray({
    control: control,
    name: 'carers',
  });

  const carers = watch('carers') || [];

  const isSame = isEqual(
    transformEmptyStringToNullAndNormalizeNumbers(carers[index].address),
    transformEmptyStringToNullAndNormalizeNumbers(initialData.address)
  );

  const onToggle = () => {
    // this id is added by react-hook-form and dont need for the form
    const carer = omit(carers[index], 'id');

    if (isSame) {
      updateCarer(index, {
        ...carer,
        address: defaultCarer.address,
      });
      return;
    }

    updateCarer(index, {
      ...carer,
      address: {
        lineOne: initialData.address.lineOne || '',
        lineTwo: initialData.address.lineTwo || null,
        lineThree: initialData.address.lineThree || null,
        lineFour: initialData.address.lineFour || null,
        postcode: initialData.address.postcode || '',
        uprn: initialData.address.uprn || null,
        eastings: initialData.address.eastings?.toString() || null,
        northings: initialData.address.northings?.toString() || null,
      },
    });
  };

  return (
    <>
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">
          Parent/Carer {index + 1}
        </p>
      </div>

      <div className="col-span-2 space-y-6">
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="block w-full sm:w-[100px] sm:min-w-[100px]">
            <Select
              label="Title"
              options={personTitleOptions}
              name={`carers[${index}].title`}
              disabled={disabled}
            />
          </div>
          <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2">
            <TextInput
              type="text"
              name={`carers[${index}].firstName`}
              label="First Name*"
              placeholder="John"
              disabled={disabled}
            />
            <TextInput
              type="text"
              name={`carers[${index}].lastName`}
              label="Last Name*"
              placeholder="John"
              disabled={disabled}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <TextInput
            type="text"
            name={`carers[${index}].mobile`}
            label="Phone Number*"
            placeholder="+44"
            disabled={disabled}
          />
          <TextInput
            type="email"
            name={`carers[${index}].email`}
            label="Email*"
            placeholder="email@address.com"
            disabled={disabled}
          />
        </div>
        <Toggle
          value={isSame}
          label="Address the same"
          description="Does parent/carer 1 live at the same address as the child?"
          onChange={onToggle}
          disabled={disabled}
        />
        {!isSame && (
          <div className="flex flex-col gap-4">
            <Address
              disabled={disabled}
              fieldNamePrefix={`carers[${index}].`}
            />
          </div>
        )}
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          {template.enabledQuestions.includes(
            'parent-carer-can-collect-child'
          ) && (
            <Checkbox
              label="Can Collect Child"
              helperText="This parent/carer can collect child from their nursery"
              name={`carers[${index}].canCollectChild`}
              disabled={disabled}
            />
          )}

          {template.enabledQuestions.includes(
            'parent-carer-emergency-contact'
          ) && (
            <Checkbox
              label="Emergency Contact"
              helperText="Can be contact in case of emergency"
              name={`carers[${index}].isEmergencyContact`}
              disabled={disabled}
            />
          )}

          {template.enabledQuestions.includes('parent-carer-occupation') && (
            <TextInput
              name={`carers[${index}].occupation`}
              label="Place of Work/Training/Education"
              placeholder="Place Details"
              disabled={disabled}
            />
          )}
          {template.enabledQuestions.includes(
            'parent-carer-occupation-schedule'
          ) && (
            <TextInput
              name={`carers[${index}].occupationSchedule`}
              label="Days & Hours of Work/Training/Education"
              placeholder="Day & Time Details"
              disabled={disabled}
            />
          )}
        </div>
      </div>
    </>
  );
}

export { CarerItem };
