import { locationKey } from '@/config/query-keys';
import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import { Location } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useLocation(
  id?: string,
  queryOptions?: Partial<UseQueryOptions<Location, HttpError>>
) {
  return useQuery({
    queryKey: locationKey.single(id || ''),
    queryFn: () => locationService.getLocation(id || ''),
    enabled: !!id,
    ...queryOptions,
  });
}

export { useLocation };
