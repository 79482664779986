import { Alert } from '@/components/alert';
import { LoadingScreen } from '@/components/loading-screen';
import { ScrollableModal } from '@/components/scroll-modal';
import { useChild } from '@/hooks/query-hooks/use-child';
import { useUpdateChildMutation } from '@/hooks/update-hooks/use-update-child-mutation';
import { getErrorMessage } from '@/utils/get-error-message';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router';
import { MakePaymentForm } from './make-payment.form';
import { getAvailablePaymentOptions } from './make-payment.helper';
import { PaymentOptions } from './payment-options.enum';
import { useMakePaymentForm } from './use-make-payment-form';

type MakePaymentModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

function MakePaymentModal(props: MakePaymentModalProps) {
  const { isOpen, onClose } = props;
  const { id = '' } = useParams<{ id: string }>();
  const {
    data: child,
    isLoading: isChildLoading,
    isError: isChildError,
    error: childError,
    isSuccess: isChildSuccess,
  } = useChild(id, {
    refetchOnWindowFocus: false,
    enabled: Boolean(id),
  });

  const {
    mutateAsync,
    isPending: isChildUpdating,
    reset: resetUpdateChildMutation,
  } = useUpdateChildMutation(id);

  const { form, handleSubmit, defaultValues } = useMakePaymentForm({
    initialData: child,
    onSubmit: mutateAsync,
  });

  /**
   * Loading Screen Modal
   */
  if (isChildLoading) {
    return (
      <ScrollableModal isOpen={isOpen} onClose={onClose} size="xl">
        <ScrollableModal.Header onClose={onClose}>
          <ScrollableModal.Title
            title="Make Payment"
            description="Select from the payment options below"
          />
        </ScrollableModal.Header>
        <ScrollableModal.Content>
          <div className="my-6">
            <LoadingScreen />
          </div>
        </ScrollableModal.Content>
      </ScrollableModal>
    );
  }

  /**
   * Error Screen Modal
   */
  if (isChildError || !isChildSuccess) {
    return (
      <ScrollableModal isOpen={isOpen} onClose={onClose} size="xl">
        <ScrollableModal.Header onClose={onClose}>
          <ScrollableModal.Title
            title="Make Payment"
            description="Select from the payment options below"
          />
        </ScrollableModal.Header>
        <ScrollableModal.Content>
          <Alert type="error" text={getErrorMessage(childError)} />
        </ScrollableModal.Content>
      </ScrollableModal>
    );
  }

  const availablePaymentOptions = getAvailablePaymentOptions(
    child.extraHours.paymentMethods
  );

  const resetFormAfterClosing = () => {
    resetUpdateChildMutation();
    form.reset(defaultValues);
  };

  /**
   * Success Message Modal
   */
  if (form.formState.isSubmitSuccessful) {
    const paymentOptionId = form.getValues().paymentOption;
    const selectedPaymentOption = PaymentOptions[paymentOptionId];

    const isEpayCapita = paymentOptionId === 'EPAY_CAPITA';
    const isTaxFreeChildcare = paymentOptionId === 'TAX_FREE_CHILD_CARE';
    const isChildcareVoucher = paymentOptionId === 'CHILDCARE_VOUCHERS';

    return (
      <ScrollableModal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        afterLeave={resetFormAfterClosing}
      >
        <ScrollableModal.Header onClose={onClose}>
          <ScrollableModal.Title title="Payment Intent Successful" />
        </ScrollableModal.Header>
        <ScrollableModal.Content className="space-y-2">
          {isEpayCapita && (
            <p>
              We have opened WLC Secure Payments in a new tab, where you can
              continue with your payment. Payments made will be reflected on
              your dashboard within 24h. You will receive a receipt upon payment
              success
            </p>
          )}
          {!isEpayCapita && (
            <>
              <Alert
                type="success"
                text="Your Payment Method has been defined"
              />
              <p>
                You have selected {selectedPaymentOption.name}, we will now
                review your account and update as necessary.
              </p>
            </>
          )}

          {isTaxFreeChildcare && (
            <div className="space-y-2">
              <p className="mt-4 font-bold">
                Here’s how you can continue make your payments:
              </p>
              <ul className="ml-8 list-decimal space-y-1 text-base">
                <li>
                  Visit the Tax-Free Childcare website at{' '}
                  <a
                    href="https://www.gov.uk/sign-in-childcare-account"
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                  >
                    https://www.gov.uk/sign-in-childcare-account
                  </a>
                  .
                </li>
                <li>Log in with your existing account details.</li>
                <li>
                  Follow the prompts to make your payment and confirm your
                  childcare provider details.
                </li>
              </ul>
            </div>
          )}

          {isChildcareVoucher && (
            <div className="space-y-2">
              <p className="mt-4 font-bold">
                Here’s how you can continue make your payments:
              </p>
              <ul className="ml-8 list-decimal space-y-1 text-base">
                <li>Log in to your childcare voucher provider account.</li>
                <li>
                  Follow the steps to make your payment and confirm your
                  childcare provider details.
                </li>
              </ul>
            </div>
          )}
        </ScrollableModal.Content>
        <ScrollableModal.Footer>
          <button className="btn btn-primary btn-full" onClick={onClose}>
            Done
          </button>
        </ScrollableModal.Footer>
      </ScrollableModal>
    );
  }

  /**
   * Form Modal
   */
  return (
    <ScrollableModal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      afterLeave={resetFormAfterClosing}
    >
      <ScrollableModal.Header onClose={onClose}>
        <ScrollableModal.Title
          title="Make Payment"
          description="Select from the payment options below"
        />
      </ScrollableModal.Header>
      <FormProvider {...form}>
        {availablePaymentOptions.length < 1 ? (
          <ScrollableModal.Content>
            <Alert
              type="warning"
              text="You have no payment method enabled! Please contact us!"
              className="mb-6"
            />
          </ScrollableModal.Content>
        ) : (
          <MakePaymentForm
            paymentOptions={availablePaymentOptions}
            onSubmit={handleSubmit}
            childId={id}
            disabled={isChildUpdating}
          />
        )}
      </FormProvider>
    </ScrollableModal>
  );
}

export { MakePaymentModal };
