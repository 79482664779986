import { axiosClient } from '@/libs/axios';
import { SeemisAddress } from '@admissions-support/types';

// TODO: Type query with @admissions-support/types once the BE PRs are merged
const getAddresses = async (query: {
  postCode: string;
}): Promise<SeemisAddress[]> => {
  const response = await axiosClient.get<SeemisAddress[]>(
    `/addresses?postcode=${encodeURIComponent(query.postCode.toLowerCase())}`
  );

  return response.data;
};

export const addressesService = Object.freeze({ getAddresses });
