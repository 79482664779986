import { StateResource } from '@/types/location-resource';
import { createEmptyResourceGroup } from '@/utils/location-resource-utils';
import { getOperatingDaysOfSessionType } from '@/utils/location-session-utils';
import { RatioCapacity, SessionType } from '@admissions-support/types';
import { format } from 'date-fns';
import { merge, uniq, uniqueId } from 'lodash-es';
import { useReducer } from 'react';

type LocationResourceForm = {
  sessionTypes: SessionType[];
  sessionTypeIds: string[];
  resources: StateResource[];
  ratioCapacities: RatioCapacity[];
};

const initialLocationResourceForm: LocationResourceForm = {
  sessionTypes: [], // list of sessions to look up session types by id
  sessionTypeIds: [],
  resources: [],
  ratioCapacities: [],
};

enum LocationResourceFormActionTypes {
  SET_SESSSION_TYPES = 'SET_SESSSION_TYPES',
  TOGGLE_SAME_CAPACITY = 'TOGGLE_SAME_CAPACITY',
  SET_GENERAL_CAPACITY = 'SET_GENERAL_CAPACITY',
  SET_CAPACITY = 'SET_CAPACITY',
  SET_RATIO_CAPACITY = 'SET_RATIO_CAPACITY',
  ADD_RESOURCE_GROUP = 'ADD_RESOURCE_GROUP',
  REMOVE_RESOURCE_GROUP = 'REMOVE_RESOURCE_GROUP',
  SET_SCHEDULED_FROM_DATE = 'SET_SCHEDULED_FROM_DATE',
}

type Action =
  | {
      type: LocationResourceFormActionTypes.SET_SESSSION_TYPES;
      payload: {
        /** If its the first resource, it should be the start of the school year */
        defaultStartDate?: string;
        sessionTypeIds: string[];
      };
    }
  | {
      type: LocationResourceFormActionTypes.TOGGLE_SAME_CAPACITY;
      payload: { uid: string; ratioId: string };
    }
  | {
      type: LocationResourceFormActionTypes.SET_GENERAL_CAPACITY;
      payload: {
        ratioId: string;
        capacity: number | null;
        uid: string;
      };
    }
  | {
      type: LocationResourceFormActionTypes.SET_CAPACITY;
      payload: {
        day: string;
        ratioId: string;
        capacity: number | null;
        uid: string;
      };
    }
  | {
      type: LocationResourceFormActionTypes.SET_RATIO_CAPACITY;
      payload: {
        ratioId: string;
        capacity: number | null;
      }[];
    }
  | {
      type: LocationResourceFormActionTypes.ADD_RESOURCE_GROUP;
    }
  | {
      type: LocationResourceFormActionTypes.SET_SCHEDULED_FROM_DATE;
      payload: {
        uid: string;
        newDate: string;
      };
    }
  | {
      type: LocationResourceFormActionTypes.REMOVE_RESOURCE_GROUP;
      payload: {
        uid: string;
      };
    };

function reducer(
  state: LocationResourceForm,
  action: Action
): LocationResourceForm {
  switch (action.type) {
    case LocationResourceFormActionTypes.SET_SESSSION_TYPES: {
      if (action.payload.sessionTypeIds.length < 1) {
        return {
          ...state,
          sessionTypeIds: [],
          resources: [],
        };
      }

      // no session type was selected earlier,
      // so lets build the basic resource array
      if (state.sessionTypeIds.length < 1) {
        const selectedSessionTypeId = action.payload.sessionTypeIds[0];

        const selectedSessionType = state.sessionTypes.find(
          sessionType => sessionType.id === selectedSessionTypeId
        );

        // not possible, just leave it here to not worry about undefined session
        if (!selectedSessionType) {
          return {
            ...state,
            sessionTypeIds: [],
            resources: [],
          };
        }

        const operatingDays =
          getOperatingDaysOfSessionType(selectedSessionType);

        const ratioResourceGroups: StateResource[] = [
          {
            from:
              action.payload.defaultStartDate ||
              format(new Date(), 'yyyy-MM-dd'),
            uid: uniqueId('resource-'),
            resourceGroups: createEmptyResourceGroup(
              operatingDays,
              state.ratioCapacities.map(rc => rc.ratio.id.toString())
            ),
          },
        ];

        return {
          ...state,
          sessionTypeIds: action.payload.sessionTypeIds,
          resources: ratioResourceGroups,
        };
      }

      return {
        ...state,
        sessionTypeIds: action.payload.sessionTypeIds,
      };
    }

    case LocationResourceFormActionTypes.TOGGLE_SAME_CAPACITY: {
      const ratioId = action.payload.ratioId;
      const uid = action.payload.uid;

      const newStateResources = state.resources.map(resource => {
        if (resource.uid === uid) {
          return {
            ...resource,
            resourceGroups: resource.resourceGroups.map(resourceGroup => {
              if (resourceGroup.ratioId === ratioId) {
                const isAllCapacityTheSame =
                  uniq(resourceGroup.capacityGroup.map(cg => cg.capacity))
                    .length === 1;
                // If toggle turned to true and not all capacities are the same, make them 0
                if (
                  !resourceGroup.hasSameCapacityForEachDay &&
                  !isAllCapacityTheSame
                ) {
                  return {
                    ...resourceGroup,
                    capacityGroup: resourceGroup.capacityGroup.map(
                      capacityGroup => ({
                        ...capacityGroup,
                        capacity: 0,
                      })
                    ),
                    hasSameCapacityForEachDay:
                      !resourceGroup.hasSameCapacityForEachDay,
                  };
                }
                return {
                  ...resourceGroup,
                  hasSameCapacityForEachDay:
                    !resourceGroup.hasSameCapacityForEachDay,
                };
              }
              return resourceGroup;
            }),
          };
        }
        return resource;
      });

      return {
        ...state,
        resources: newStateResources,
      };
    }

    case LocationResourceFormActionTypes.SET_GENERAL_CAPACITY: {
      const ratioId = action.payload.ratioId;
      const uid = action.payload.uid;
      const newCapacity = action.payload.capacity;

      const newStateResources: StateResource[] = state.resources.map(
        resource => {
          if (resource.uid === uid) {
            return {
              ...resource,
              resourceGroups: resource.resourceGroups.map(resourceGroup => {
                if (resourceGroup.ratioId === ratioId) {
                  return {
                    ...resourceGroup,
                    capacityGroup: resourceGroup.capacityGroup.map(
                      capacityGroup => {
                        return {
                          ...capacityGroup,
                          capacity: newCapacity || null,
                        };
                      }
                    ),
                  };
                }
                return resourceGroup;
              }),
            };
          }
          return resource;
        }
      );

      return {
        ...state,
        resources: newStateResources,
      };
    }

    case LocationResourceFormActionTypes.SET_CAPACITY: {
      const ratioId = action.payload.ratioId;
      const uid = action.payload.uid;
      const day = action.payload.day;
      const newCapacity = action.payload.capacity;

      const newStateResources: StateResource[] = state.resources.map(
        resource => {
          if (resource.uid === uid) {
            return {
              ...resource,
              resourceGroups: resource.resourceGroups.map(resourceGroup => {
                if (resourceGroup.ratioId === ratioId) {
                  return {
                    ...resourceGroup,
                    capacityGroup: resourceGroup.capacityGroup.map(
                      capacityGroup => {
                        if (capacityGroup.day === day) {
                          return {
                            ...capacityGroup,
                            capacity: newCapacity || null,
                          };
                        }
                        return capacityGroup;
                      }
                    ),
                  };
                }
                return resourceGroup;
              }),
            };
          }
          return resource;
        }
      );

      return {
        ...state,
        resources: newStateResources,
      };
    }

    case LocationResourceFormActionTypes.SET_RATIO_CAPACITY: {
      return {
        ...state,
        ratioCapacities: state.ratioCapacities.map(rc => {
          const matchingRatio = action.payload.find(
            ratio => ratio.ratioId === rc.ratio.id.toString()
          );
          if (matchingRatio) {
            return {
              ...rc,
              capacity: matchingRatio.capacity || 0,
            };
          }

          return { ...rc, capacity: 0 };
        }),
      };
    }

    case LocationResourceFormActionTypes.ADD_RESOURCE_GROUP: {
      const firstSelectedSessionType = state.sessionTypes.find(
        sessionType => sessionType.id === state.sessionTypeIds[0]
      );
      if (!firstSelectedSessionType) {
        return {
          ...state,
        };
      }

      const operatingDays = getOperatingDaysOfSessionType(
        firstSelectedSessionType
      );

      const emptyResourceGroup = createEmptyResourceGroup(
        operatingDays,
        state.ratioCapacities.map(rc => rc.ratio.id.toString())
      );

      const newStateResources: StateResource[] = state.resources.concat({
        from: '',
        uid: uniqueId('resource-'),
        resourceGroups: emptyResourceGroup,
      });

      return {
        ...state,
        resources: newStateResources,
      };
    }

    case LocationResourceFormActionTypes.SET_SCHEDULED_FROM_DATE: {
      const uid = action.payload.uid;
      const newDate = action.payload.newDate;

      const newStateResources: StateResource[] = state.resources.map(
        resource => {
          if (resource.uid === uid) {
            return {
              ...resource,
              from: newDate,
            };
          }
          return resource;
        }
      );

      return {
        ...state,
        resources: newStateResources,
      };
    }

    case LocationResourceFormActionTypes.REMOVE_RESOURCE_GROUP: {
      const uid = action.payload.uid;

      const newStateResources: StateResource[] = state.resources.filter(
        resource => resource.uid !== uid
      );

      return {
        ...state,
        resources: newStateResources,
      };
    }
  }
}

function useLocationResourceFormReducer(
  ratioCapacities: RatioCapacity[],
  sessionTypes: SessionType[],
  initialState?: Partial<LocationResourceForm>
) {
  const defaultState = {
    ...initialLocationResourceForm,
    ratioCapacities,
    sessionTypes,
  };

  // any overlapping properties in initialState will overwrite those in defaultState
  return useReducer(reducer, merge({}, defaultState, initialState));
}

export { LocationResourceFormActionTypes, useLocationResourceFormReducer };
export type { LocationResourceForm };
