import { getSessionDetailsFromChoice } from '@/utils/application-utils';
import { TableChoice } from '@admissions-support/types';
import { uniqueId } from 'lodash-es';
import { Tooltip } from 'react-tooltip';

type ChoiceTableCellProps = {
  choice: TableChoice;
};

function ChoiceTableCell(props: ChoiceTableCellProps) {
  const { choice } = props;

  if (!choice) {
    return null;
  }

  const sessionDetails = getSessionDetailsFromChoice(choice);

  if (!sessionDetails) {
    return null;
  }

  const choiceKey = uniqueId('choice-item');
  return (
    <>
      {sessionDetails.status === 'WAITING_LIST' && (
        <>
          <Tooltip
            anchorSelect={`#${choiceKey}`}
            content="Waiting List"
            place="top"
            className="tooltip"
          />
          <div className="absolute inset-0 bottom-[-1px] border-b border-yellow-500 bg-yellow-50" />
        </>
      )}
      {sessionDetails.status === 'SCORED' && (
        <>
          <Tooltip
            anchorSelect={`#${choiceKey}`}
            content="Matching not run"
            place="top"
            className="tooltip"
          />
          <div className="absolute inset-0 bg-gray-50" />
        </>
      )}
      <p className="relative cursor-pointer" id={choiceKey}>
        <span className="block text-gray-900">{sessionDetails.title}</span>
        <span className="block text-gray-600">{sessionDetails.days}</span>
      </p>
    </>
  );
}

export { ChoiceTableCell };
