import { Dropzone } from '@/components/form/common/dropzone';

import { paths } from '@/config/upload-paths';
import { CatchmentAreaVersion } from '@admissions-support/types';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useFormContext } from 'react-hook-form';
import { useFile } from '@/hooks/use-file';
import { format } from 'date-fns';

type UploadCatchmentAreasProps = {
  isDisabled: boolean;
  catchmentAreas: CatchmentAreaVersion[];
};

function UploadCatchmentAreas(props: UploadCatchmentAreasProps) {
  const { isDisabled, catchmentAreas } = props;

  const form = useFormContext();

  const catchmentAreaFiles = form.watch('catchmentAreaFiles');

  const [queryKey, setQueryKey] = useState('');

  const {
    data: file,
    isSuccess: isFileSuccess,
    isError: isFileError,
  } = useFile(queryKey, import.meta.env['VITE_BUCKET'], {
    enabled: Boolean(queryKey),
  });

  const fileUrl = isFileSuccess ? file.url.toString() : undefined;
  useEffect(() => {
    if (isFileError) {
      toast.error('File preview failed. Please try again.');
    }

    if (!fileUrl) {
      return;
    }

    window.open(fileUrl, '_blank');
    setQueryKey('');
  }, [isFileError, fileUrl]);

  const handleDownload = async (key: string) => {
    setQueryKey(key);
  };

  const lastCatchmentArea =
    catchmentAreas.length > 0
      ? catchmentAreas[catchmentAreas.length - 1]
      : undefined;

  return (
    <div className="two-col-form gap-6 py-6">
      <div>
        <p className="font-semibold text-gray-700">Upload Catchment Areas</p>{' '}
        <p className="text-gray-600">
          Uploading GeoJSON will create schools that don’t yet exist, or update
          polygon for schools that have already been created. Schools are
          identified by their Seed Number.
        </p>
      </div>
      <div className="col-span-2 space-y-6">
        <Dropzone
          name="catchmentAreaFiles"
          path={paths.catchmentAreas}
          options={{
            disabled: isDisabled,
            multiple: false,
            accept: {
              'application/json': ['.json', '.geojson'],
            },
          }}
          disableFileUpload={catchmentAreaFiles.length > 0}
          fileRestrictionLabel="Only JSON files are accepted."
        />
        {lastCatchmentArea?.released && (
          <div className="flex items-center gap-4">
            <p className="text-sm text-gray-600">
              Last upload {format(lastCatchmentArea.released, 'dd/MM/yy')}
            </p>
            <button
              className="text-gray-900"
              onClick={() => {
                handleDownload(lastCatchmentArea.file);
              }}
            >
              {lastCatchmentArea.file
                .split('/')[1]
                .split('_')
                .slice(1)
                .join('_')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export { UploadCatchmentAreas };
