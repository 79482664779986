import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { UpsertCatchmentAreasFormData } from './upsert-catchment-areas.type';
import { upsertCatchmentAreasFormSchema } from './upsert-catchment-areas.schema';

type UseUpsertCatchmentAreasFormProps = {
  onSubmit: (data: UpsertCatchmentAreasFormData) => Promise<void>;
};

const defaultValues: UpsertCatchmentAreasFormData = {
  catchmentAreaFiles: [],
};

function useUpsertCatchmentAreasForm(props: UseUpsertCatchmentAreasFormProps) {
  const { onSubmit } = props;

  const form = useForm({
    resolver: yupResolver(upsertCatchmentAreasFormSchema),
    defaultValues: defaultValues,
  });

  const handleSubmit = async (data: UpsertCatchmentAreasFormData) => {
    try {
      const response = await onSubmit(data);

      return response;
    } catch (error) {
      const httpError = error as HttpError;
      if (isBadRequest(httpError)) {
        const availableFields = getNestedKeys(form.getValues());
        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }
            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }
          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }
      form.setError('root.server', {
        message: Array.isArray(httpError.message)
          ? httpError.message[0]
          : httpError.message,
      });
    }
  };

  return {
    form,
    handleSubmit,
  };
}

export { useUpsertCatchmentAreasForm };
