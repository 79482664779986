import { useChildBookingsInfo } from '@/hooks/query-hooks/use-child-bookings-info';
import { useModal } from '@/hooks/use-modal';
import { MakePaymentModal } from '@/pages/parent/children/make-payment/make-payment-modal';
import { ApplicationStatusTag } from '../application/application-status-tag';
import { Box } from '../box';
import { FallbackComponent } from '../fallback-component';
import { ActivityLogDrawer } from './activity-log/activity-log-drawer';

type ParentPaymentProps = {
  childId: string;
};

function ParentPayment(props: ParentPaymentProps) {
  const { childId } = props;

  const {
    data: bookingsMeta,
    isError: isBookingsMetaError,
    isSuccess: isBookingsMetaSuccess,
    isLoading: isBookingsMetaLoading,
  } = useChildBookingsInfo(childId);
  const { isOpen, openModal, closeModal } = useModal();

  const {
    isOpen: isActivityLogOpen,
    openModal: openActivityLog,
    closeModal: closeActivityLog,
  } = useModal();

  if (isBookingsMetaLoading) {
    return (
      <Box className="flex w-full flex-col p-5">
        <p className="text-md font-semibold">Payments</p>
        <p className="mb-4 text-sm text-gray-600">Select payment option</p>

        <button className="btn btn-primary mt-auto self-start" disabled>
          Make Payment
        </button>
      </Box>
    );
  }

  if (!isBookingsMetaSuccess || isBookingsMetaError) {
    return (
      <Box className="w-full p-5">
        <FallbackComponent />
      </Box>
    );
  }

  return (
    <Box className="flex w-full flex-col p-5">
      <div className="mb-4 flex items-center justify-between sm:block lg:flex">
        <div>
          <p className="text-md font-semibold">Payments</p>
          <p className="text-sm text-gray-600">Select payment option</p>
        </div>
        {bookingsMeta.nbOfDueBooking > 0 && (
          <ApplicationStatusTag status="PLACEMENT_REJECTED">
            {bookingsMeta.nbOfDueBooking} Payment
            {bookingsMeta.nbOfDueBooking > 1 ? 's' : ''} Due
          </ApplicationStatusTag>
        )}
      </div>
      <div className="flex flex-wrap gap-4">
        <button
          className="btn btn-primary mt-auto self-start"
          onClick={openModal}
          type="button"
        >
          Make Payment
        </button>
        <button
          className="btn btn-secondary"
          type="button"
          onClick={openActivityLog}
        >
          Activity Log
        </button>
      </div>

      <MakePaymentModal isOpen={isOpen} onClose={closeModal} />
      <ActivityLogDrawer
        onClose={closeActivityLog}
        isOpen={isActivityLogOpen}
        childId={childId}
      />
    </Box>
  );
}

export { ParentPayment };
