import {
  ChoiceState,
  initialChoiceForm,
  initialSession,
} from '@/reducers/choice.reducer';
import { ApplicationFilter } from '@/types/application-filter';
import { DayOfWeek } from '@/types/sessions';
import {
  ChildMedicalDetails,
  Choice,
  ChoiceStatus,
  IOperatingDaySession,
  ISession,
  Location,
  OperatingDays,
  OperatingDaySession,
  OperatingDaySessionDto,
  Ratio,
  RequestedSessionSchedule,
  SeemisAddress,
  Session,
  SessionDayDetails,
  SessionSchedule,
  SessionTiming,
  SessionType,
  TableChoice,
} from '@admissions-support/types';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { differenceInMonths, format, startOfDay } from 'date-fns';
import { isEmpty, isNil, mapValues, merge, pick, uniqBy } from 'lodash-es';
import { arrayify } from './array-utils';
import { listRequestedDaysInSessionSchedule } from './choice-utils';
import {
  formatCompactScheduleDays,
  formatOperatingDays,
} from './format-operating-days';

function transformEmptyStringToNullAndNormalizeNumbers<T extends object | null>(
  obj: T
): T {
  if (obj === null) {
    return null as T;
  }
  const result = obj as unknown as Record<string, unknown>;

  for (const prop in result) {
    if (
      result[prop] &&
      typeof result[prop] === 'object' &&
      !Array.isArray(result[prop]) &&
      result[prop] !== null
    ) {
      result[prop] = transformEmptyStringToNullAndNormalizeNumbers(
        result[prop]
      );
    } else if (typeof result[prop] === 'string') {
      if (result[prop] === '') {
        result[prop] = null;
      } else if (!isNaN(Number(result[prop]))) {
        result[prop] = Number(result[prop]);
      }
    }
  }
  return result as T;
}

// Function to replace empty strings with null.
function transformEmptyStringToNull<T>(obj: T): T {
  for (const prop in obj) {
    if (typeof obj[prop] === 'object' && obj[prop] !== null) {
      transformEmptyStringToNull(obj[prop]);
    } else if (obj[prop] === '') {
      obj[prop] = null as any; // Using any for simplicity, but stricter type checks could be applied.
    }
  }
  return obj;
}

type SelectedReturnType<T, Keys extends keyof T> = Pick<T, Keys>;

// Function to select specific keys from an object.
function selectKeysFromObject<T, Keys extends keyof T>(
  obj: T,
  keys: Keys[]
): SelectedReturnType<T, Keys> {
  return pick(obj, keys) as SelectedReturnType<T, Keys>;
}

// Function to transform form data to DTO
function transformFormDataToDto<T>(obj: T): T;
function transformFormDataToDto<T, Keys extends keyof T>(
  obj: T,
  keys: Keys[]
): SelectedReturnType<T, Keys>;
function transformFormDataToDto<T, Keys extends keyof T>(
  obj: T,
  keys?: Keys[]
): T | SelectedReturnType<T, Keys> {
  const transformedObj = transformEmptyStringToNull(obj);
  if (keys) {
    return selectKeysFromObject(transformedObj, keys);
  }
  return transformedObj;
}

function transformScore(number?: number | null) {
  if (!number) {
    return;
  }

  return parseFloat((number * 100).toFixed(2));
}

function getAvailableModelsFromLocation(
  location?: Location
): { id: string; name: string }[] {
  if (!location) {
    return [];
  }

  const locationsWithUniqueSession = uniqBy(location.sessions, 'model.id');

  return locationsWithUniqueSession.map(session => ({
    id: session.model.id.toString(),
    name: session.model.name,
  }));
}

function selectAvailableSessionTypes(
  state: ChoiceState,
  sessionTypes: SessionType[]
): { id: string; name: string }[] {
  if (!state.sessions) {
    return [];
  }

  const filteredSessions = state.sessions
    .filter(session => session.schoolYear.id.toString() === state.schoolYearId)
    .filter(session => {
      if (state.model) {
        return session.model.id.toString() === state.model;
      }

      return true;
    });
  const sessionsWithUniqueSessionType = uniqBy(
    filteredSessions,
    'type.id'
  ).filter(session => {
    const sessionType = sessionTypes.find(
      sessionType => sessionType.id === session.type.id.toString()
    );

    return !sessionType?.isAdditionalSessionType;
  });

  return sessionsWithUniqueSessionType.map(session => ({
    id: session.type.id.toString(),
    name: session.type.name,
  }));
}

function selectAvailableSessions(state: ChoiceState): Session[] {
  if (!state.sessions) {
    return [];
  }

  const availableSessions = state.sessions
    .filter(session => session.schoolYear.id.toString() === state.schoolYearId)
    .filter(session => session.type.id.toString() === state.sessionType)
    .filter(session => {
      if (state.model) {
        return session.model.id.toString() === state.model;
      }

      return true;
    });

  return availableSessions;
}

function selectSingleSession(state: ChoiceState): string | undefined {
  const availableSessions = selectAvailableSessions(state);

  const isSingleSessionIsAvailable = availableSessions.length === 1;

  if (isSingleSessionIsAvailable) {
    return availableSessions[0].id;
  }

  return undefined;
}

type SessionTimeResult = {
  sessionId: string;
  details: SessionDayDetails;
  day: string;
};

function getSessionDayDetailsForDay(
  sessions: Session[],
  day: string
): SessionTimeResult[] {
  // Asserting that 'day' can be used as a key for 'SessionTiming'
  const dayKey = day.toLowerCase() as keyof SessionTiming;

  return sessions
    .filter(session => session.times && session.times[dayKey])
    .map(session => ({
      day: dayKey,
      sessionId: session.id,
      details: session.times[dayKey]!,
    }))
    .filter(result => result.details !== null); // filter out null details
}

function getOperatingDays(days: string[]): OperatingDays {
  return {
    monday: days.includes('monday'),
    tuesday: days.includes('tuesday'),
    wednesday: days.includes('wednesday'),
    thursday: days.includes('thursday'),
    friday: days.includes('friday'),
    saturday: days.includes('saturday'),
    sunday: days.includes('sunday'),
  };
}

/**
 * Formats a given list of requested days (including `any`) as a map of boolean
 * (`true` if the key is contained in {@link requestedDays}, `false` else)
 *
 * Similar to {@link getOperatingDays} except it also handles `any`
 * @param requestedDays
 * @returns
 */
function formatRequestedDaysAsBooleanMap(
  requestedDays: string[]
): Record<keyof RequestedSessionSchedule, boolean> {
  const entries = ['any', ...Object.values(DayOfWeek)].map(
    key =>
      [key, requestedDays.includes(key)] as [
        keyof RequestedSessionSchedule,
        boolean
      ]
  );
  const map = Object.fromEntries(entries) as Record<
    keyof RequestedSessionSchedule,
    boolean
  >;

  return map;
}

function transformInitialChoiceValue({
  schoolYearId,
  choice,
}: {
  schoolYearId: string;
  choice?: Choice;
}): ChoiceState {
  if (!choice) {
    return {
      ...initialChoiceForm,
      schoolYearId: schoolYearId,
    };
  }
  const splitPlacementSessionsPattern: OperatingDaySessionDto = Object.keys(
    initialSession
  ).reduce((pattern, currentDay) => {
    if (!choice.splitPlacement || !choice.splitPlacement.sessions) {
      return {
        ...pattern,
        [currentDay]: null,
      };
    }

    const session =
      choice.splitPlacement.sessions[currentDay as keyof OperatingDaySession];
    return {
      ...pattern,
      [currentDay]: session ? session.toString() : null,
    };
  }, {} as OperatingDaySessionDto);

  const sessionsPattern = mapValues(
    choice.sessions,
    arrayableSession => arrayify(arrayableSession)[0]?.toString() || null
  );

  return {
    ...initialChoiceForm,
    isExisting: true,
    establishment: choice.location.id.toString(),
    model: '',
    sessionType: '',
    schoolYearId: schoolYearId,
    sessionsPattern,
    additionalSessionsPattern: Object.keys(choice.additionalSessions).reduce(
      // this is needed because the type lib does not return type properly
      // this part is converting session id-s from ObjectId to string
      (pattern, currentDay) => ({
        ...pattern,
        [currentDay]:
          choice.additionalSessions[
            currentDay as keyof OperatingDaySession
          ]?.toString() || null,
      }),
      {} as OperatingDaySessionDto
    ),
    enableSplitPlacement:
      !isNil(choice.splitPlacement) && !isNil(choice.splitPlacement.location),
    enableDifferentEstablishmentForAdditionalSessions:
      !isNil(choice.splitPlacement) &&
      !isNil(choice.splitPlacement.additionalSessionLocation),
    splitPlacementEstablishmentId:
      !isNil(choice.splitPlacement) && choice.splitPlacement.location
        ? choice.splitPlacement.location.id.toString()
        : '',
    splitPlacementSessionsPattern,
    /**
     * If user didnt enable split placement, we know for sure,
     * that additionalSessionsEstablishmentId is going to be the same as establishment(id).
     * If user did enable split placement, we need to check if they selected a different location
     * for additional sessions or not.
     */
    additionalSessionsEstablishmentId: isNil(choice.splitPlacement)
      ? choice.location.id.toString()
      : isNil(choice.splitPlacement.additionalSessionLocation)
      ? ''
      : choice.splitPlacement.additionalSessionLocation.id.toString(),
  };
}

/**
 * Counts how many session is selected. This is important, because
 * every session type has different allowance. If we split the placement,
 * we need also count in the sessions at the other location as well.
 */
function selectNbOfSelectedSessions(state: ChoiceState): number {
  const sessionDays = Object.keys(
    state.sessionsPattern
  ) as (keyof typeof state.sessionsPattern)[];

  const nbOfNotNullDays = sessionDays.reduce((counter, day) => {
    const arrayableSession = state.sessionsPattern[day];
    if (!arrayableSession) {
      return counter;
    }

    const sessions = arrayify(arrayableSession);
    return counter + sessions.length;
  }, 0);

  return nbOfNotNullDays + selectNbOfSelectedSplitPlacementSessions(state);
}

function selectNbOfSelectedSplitPlacementSessions(state: ChoiceState): number {
  const splitPlacementSessionDays = Object.keys(
    state.splitPlacementSessionsPattern
  );

  const nbOfNotNullDaysAtSplitPlacement = splitPlacementSessionDays.reduce(
    (counter, day) => {
      const currentSession =
        state.splitPlacementSessionsPattern[
          day as keyof OperatingDaySessionDto
        ];

      if (currentSession) {
        return counter + 1;
      }
      return counter;
    },
    0
  );

  return nbOfNotNullDaysAtSplitPlacement;
}

function selectNbOfAdditionalSelectedSessions(state: ChoiceState): number {
  const sessionDays = Object.keys(state.sessionsPattern);

  const nbOfNotNullDays = sessionDays.reduce((counter, day) => {
    const currentSession =
      state.additionalSessionsPattern[day as keyof OperatingDaySessionDto];

    if (currentSession) {
      return counter + 1;
    }
    return counter;
  }, 0);

  return nbOfNotNullDays;
}

function selectAvailableAdditionalSessions(state: ChoiceState): Session[] {
  if (!state.sessions) {
    return [];
  }

  if (!state.additionalSessionType) {
    return [];
  }

  const sessions: Session[] = [];

  /**
   * If user selected a location for additional session
   * and that location is the same as the split placement location, we need to use
   * sessions from that location.
   */
  if (state.establishment === state.additionalSessionsEstablishmentId) {
    sessions.push(...state.sessions);
  } else {
    sessions.push(...state.sessionsAtSplitPlacement);
  }

  return sessions
    .filter(session => session.schoolYear.id.toString() === state.schoolYearId)
    .filter(
      session => session.type.id.toString() === state.additionalSessionType
    )
    .filter(session => {
      if (state.model) {
        return session.model.id.toString() === state.model;
      }

      return true;
    });
}

function selectSessionListWithDayTitle(
  state: ChoiceState
): SessionTimeResult[] {
  const availableAdditionalSessions = selectAvailableAdditionalSessions(state);

  return availableAdditionalSessions.reduce(
    (sessionOnDay: SessionTimeResult[], current) => {
      const availableDaysOfCurrentSession = Object.keys(current.times).filter(
        day => current.times[day as keyof SessionTiming] !== null
      );

      availableDaysOfCurrentSession.forEach(day => {
        sessionOnDay.push({
          day,
          sessionId: current.id,
          details: current.times[day as keyof SessionTiming]!,
        });
      });

      return sessionOnDay;
    },
    []
  );
}

/**
 * Finds the first-non null Session ID in the provided schedule.
 *
 * @param schedule
 * @returns The first non-null Session ID found formatted as a string.
 */
function findFirstSessionIdInSchedule(
  schedule: SessionSchedule | RequestedSessionSchedule
): string | null {
  const foundSessionId = Object.values({ ...schedule })
    .flat(1)
    .find(sessionId => Boolean(sessionId));

  const foundSessionIdStr = foundSessionId?.toString() || null;
  return foundSessionIdStr;
}

/**
 * @deprecated Refactored into {@link findFirstSessionIdInSchedule} to
 * centralise logic in a single function
 */
function getFirstSessionId(
  choice: Choice,
  isAdditionalSession?: boolean
): string | null {
  const { sessions, additionalSessions } = choice;
  const schedule = isAdditionalSession ? additionalSessions : sessions;

  // Empty sessions? No session IDs
  if (!schedule) {
    return null;
  }

  return findFirstSessionIdInSchedule(schedule);
}

/**
 * @deprecated Refactored into {@link findFirstSessionIdInSchedule} to
 * centralise logic in a single function
 */
function getFirstAdditionalSessionId(choice: Choice): string | null {
  return getFirstSessionId(choice, true);
}

function isApplicationWithASNChild(
  medicalDetails?: ChildMedicalDetails
): boolean {
  if (!medicalDetails) {
    return false;
  }

  if (
    medicalDetails.isAllergic ||
    medicalDetails.isAsmatic ||
    medicalDetails.isDiabetic ||
    medicalDetails.isEpileptic ||
    medicalDetails.isHearingImpaired ||
    medicalDetails.isSpeechImpaired ||
    medicalDetails.isVisuallyImpaired
  ) {
    return true;
  }
  return false;
}

function calculateRatioForDOB(dob: string | Date, ratios: Ratio[] | undefined) {
  if (!dob || !ratios || ratios.length < 1) {
    return null;
  }

  const birthDate = startOfDay(new Date(dob));
  const today = new Date();
  const ageInMonths = differenceInMonths(today, birthDate);

  for (const ratio of ratios) {
    const fromRangeInMonth =
      ratio.from.unit === 'YEARS' ? ratio.from.value * 12 : ratio.from.value;
    const toRangeInMonth =
      ratio.to.unit === 'YEARS' ? ratio.to.value * 12 : ratio.to.value;

    if (ageInMonths >= fromRangeInMonth && ageInMonths <= toRangeInMonth) {
      return ratio.name;
    }
  }

  return null;
}

/**
 * This works because the score per location is always the same
 * So in terms of scoring it does not matter what the exact choice is,
 * as far as the location is the same
 * @param tableChoices from application listing endpoint
 * @param locationId nursery id
 * @returns
 */
function getScoreByLocation(
  tableChoices: TableChoice[],
  locationId: string
): string {
  const nurseryForLocation = tableChoices.find(
    location => location.location.id === locationId
  );

  if (nurseryForLocation) {
    return (nurseryForLocation.score * 100).toFixed(2);
  }

  return '-';
}

function findFirstSession(
  sessionPattern: IOperatingDaySession
): ISession | null {
  for (const key in sessionPattern) {
    const currentSession = sessionPattern[key as keyof OperatingDays];
    if (!isEmpty(currentSession)) {
      return arrayify(currentSession)[0];
    }
  }
  return null;
}

/**
 * This is a huge data transformation function. If you want to know whats going on,
 * check the /applications endpoint's return structure. This function displays sessions from result
 * @param choice Custom choice format, just for application listing
 */
function getSessionDetailsFromChoice(choice: TableChoice): null | {
  status: ChoiceStatus;
  title: string;
  days: string;
} {
  const sessionPattern = choice.sessions || choice.schedule;
  const additionalSessionPattern = choice.additionalSessions;
  const firstSession = findFirstSession(sessionPattern);
  const firstAdditionalSession = findFirstSession(additionalSessionPattern);

  if (!firstSession) {
    return null;
  }

  const hasAdditionalSessionSelected = !!firstAdditionalSession;
  const daysChildAppliedSessionFor =
    listRequestedDaysInSessionSchedule(sessionPattern);
  const daysChildAppliedAdditionalSessionFor = Object.keys(
    additionalSessionPattern
  ).filter(day => additionalSessionPattern[day as keyof OperatingDays]);

  const sessionDays = formatCompactScheduleDays(
    formatRequestedDaysAsBooleanMap(daysChildAppliedSessionFor)
  );
  const additionalSessionDays = formatOperatingDays(
    getOperatingDays(daysChildAppliedAdditionalSessionFor)
  );

  return {
    status: choice.status || 'NOT_MATCHED',
    title: hasAdditionalSessionSelected
      ? `${firstSession.type.name} + ${firstAdditionalSession.type.name}`
      : firstSession.type.name,
    days: hasAdditionalSessionSelected
      ? `${sessionDays} + ${additionalSessionDays}`
      : sessionDays,
  };
}
/**
 * It returns back all the sessions for the location which was selected
 * for split placement
 */
function selectAvailableSplitSessions(state: ChoiceState): Session[] {
  if (!state.sessions) {
    return [];
  }

  return state.sessionsAtSplitPlacement
    .filter(session => session.schoolYear.id.toString() === state.schoolYearId)
    .filter(session => session.type.id.toString() === state.sessionType)
    .filter(session => {
      if (state.model) {
        return session.model.id.toString() === state.model;
      }

      return true;
    });
}

function generateChoiceState(state?: Partial<ChoiceState>): ChoiceState {
  return merge({}, initialChoiceForm, state);
}

function formatApplicationAddress(address: SeemisAddress) {
  const addressArray = [
    address.lineOne,
    address.lineTwo,
    address.lineThree,
    address.lineFour,
    address.postcode,
  ].filter(address => Boolean(address?.trim()));

  return addressArray.join(', ');
}

function getApplicationsQueryParams({
  pagination,
  sorting,
  filter,
  schoolYear,
}: {
  pagination?: PaginationState;
  sorting?: SortingState;
  filter: ApplicationFilter;
  schoolYear: string;
}): string[] {
  const queryParams = [`schoolYearId=${schoolYear}`];

  if (pagination) {
    queryParams.push(
      `limit=${pagination?.pageSize ? pagination.pageSize : 10}`
    );
    queryParams.push(`page=${(Number(pagination?.pageIndex) ?? 0) + 1}`);
  }

  if (sorting && sorting.length > 0) {
    const sortingBy = sorting[0];

    queryParams.push(`sortBy=${sortingBy.id}`);
    queryParams.push(`orderBy=${sortingBy.desc ? 'desc' : 'asc'}`);
  }

  if (filter.statuses.length > 0) {
    filter.statuses.forEach(status => queryParams.push(`statuses[]=${status}`));
  }

  if (filter.choices.length > 0) {
    filter.choices.forEach(choice => queryParams.push(`choices[]=${choice}`));
  }

  if (filter.locations.length > 0) {
    filter.locations.forEach(location =>
      queryParams.push(`locations[]=${location}`)
    );
  }

  if (filter.intakes.length > 0) {
    filter.intakes.forEach(intake => queryParams.push(`intakes[]=${intake}`));
  }

  if (filter.search.trim() !== '') {
    queryParams.push(`search=${filter.search}`);
  }

  if (filter.isSiblingAtLocation) {
    queryParams.push(`isSiblingAtLocation=true`);
  }

  if (filter.isLookedAfter) {
    queryParams.push(`isLookedAfter=true`);
  }

  if (filter.choiceStatuses.length > 0) {
    filter.choiceStatuses.forEach(status =>
      queryParams.push(`choiceStatuses[]=${status}`)
    );
  }

  if (filter.baseDateOfBirth) {
    const date = format(filter.baseDateOfBirth, 'yyyy-MM-dd');
    queryParams.push(`baseDateOfBirth=${date}`);
  }

  if (filter.upperDateOfBirth) {
    const date = format(filter.upperDateOfBirth, 'yyyy-MM-dd');

    queryParams.push(`upperDateOfBirth=${date}`);
  }

  return queryParams;
}

export {
  calculateRatioForDOB,
  findFirstSessionIdInSchedule,
  formatApplicationAddress,
  formatRequestedDaysAsBooleanMap,
  generateChoiceState,
  getApplicationsQueryParams,
  getAvailableModelsFromLocation,
  getFirstAdditionalSessionId,
  getFirstSessionId,
  getOperatingDays,
  getScoreByLocation,
  getSessionDayDetailsForDay,
  getSessionDetailsFromChoice,
  isApplicationWithASNChild,
  selectAvailableAdditionalSessions,
  selectAvailableSessions,
  selectAvailableSessionTypes,
  selectAvailableSplitSessions,
  selectKeysFromObject,
  selectNbOfAdditionalSelectedSessions,
  selectNbOfSelectedSessions,
  selectNbOfSelectedSplitPlacementSessions,
  selectSessionListWithDayTitle,
  selectSingleSession,
  transformEmptyStringToNull,
  transformFormDataToDto,
  transformInitialChoiceValue,
  transformScore,
  transformEmptyStringToNullAndNormalizeNumbers,
};
