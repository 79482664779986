import { staffRouterPath } from '@/config/route-paths.config';
import { useLeaveModal } from '@/hooks/use-leave-modal';
import { ApplicationFormDto } from '@/types/application';
import { HttpError, isBadRequest } from '@/types/error';
import { transformFormDataToDto } from '@/utils/application-utils';
import { formatDateToYYYYMMDD } from '@/utils/format-date';
import { getNestedKeys } from '@/utils/get-nested-keys';
import {
  Application,
  ApplicationTemplate,
  UpdateApplicationDto,
} from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { has } from 'lodash-es';
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { additionalChildDetailsSchema } from './additional-child-details.schema';
import { AdditionalChildDetailsFormData } from './additional-child-details.type';

type UseAdditionalChildDetailsFormProps = {
  initialData: Application;
  template: ApplicationTemplate;
  onSubmit: (data: UpdateApplicationDto) => Promise<Application>;
};

function transformApiDataToFormData(
  initialData: Application
): AdditionalChildDetailsFormData {
  const address: AdditionalChildDetailsFormData['address'] = {
    lineOne: initialData.address.lineOne || '',
    lineTwo: initialData.address.lineTwo || null,
    lineThree: initialData.address.lineThree || null,
    lineFour: initialData.address.lineFour || null,
    postcode: initialData.address.postcode || '',
    uprn: initialData.address.uprn || null,
    eastings: initialData.address.eastings?.toString() || null,
    northings: initialData.address.northings?.toString() || null,
  };

  const localAuthorityDetails: AdditionalChildDetailsFormData['localAuthorityDetails'] =
    {
      nursery: initialData.localAuthorityDetails?.nursery,
      isSplit: initialData.localAuthorityDetails?.isSplit,
      splitDetails: initialData.localAuthorityDetails?.splitDetails,
      isAlreadyAttending: initialData.localAuthorityDetails?.isAlreadyAttending,
      attendanceDetails: initialData.localAuthorityDetails?.attendanceDetails,
      isExtendedChildcare:
        initialData.localAuthorityDetails?.isExtendedChildcare,
    };

  return {
    address,
    homeStatus: initialData.homeStatus,
    moveInDate: initialData.moveInDate
      ? formatDateToYYYYMMDD(initialData.moveInDate)
      : '',
    medicalDetails: initialData.medicalDetails,
    lookedAfter: {
      ...initialData.lookedAfter,
      isSubjectToCourtDecision:
        initialData.lookedAfter.isSubjectToCourtDecision !== null
          ? initialData.lookedAfter.isSubjectToCourtDecision
            ? 'true'
            : 'false'
          : null,
    },
    proofOfAddress: initialData?.proofOfAddress || [],
    localAuthorityDetails: initialData.localAuthorityDetails
      ? localAuthorityDetails
      : undefined,
  };
}

function useAdditionalChildDetailsForm(
  props: UseAdditionalChildDetailsFormProps
) {
  const { initialData, onSubmit } = props;
  const isDraft = useRef(true);
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const defaultValues: AdditionalChildDetailsFormData =
    transformApiDataToFormData(initialData);

  const defaultResolver = yupResolver(additionalChildDetailsSchema);
  const form = useForm({
    resolver: (data, context, options) => {
      if (isDraft.current) {
        // if its draft no validation required
        return { errors: {}, values: data };
      }
      return defaultResolver(data, context, options);
    },
    defaultValues,
  });

  useLeaveModal({
    show: form.formState.isDirty,
  });

  useEffect(() => {
    if (!shouldRedirect) {
      return;
    }
    navigate(
      generatePath(staffRouterPath.APPLICATION_CARER, {
        id: params.id || '',
      })
    );
  }, [shouldRedirect, navigate, params.id]);

  const { reset } = form;
  useEffect(() => {
    if (!form.formState.isSubmitSuccessful) {
      return;
    }

    reset(undefined, { keepValues: true });

    if (!isDraft.current) {
      setShouldRedirect(true);
    }
  }, [form.formState.isSubmitSuccessful, params.id, navigate, reset]);

  useEffect(() => {
    reset(transformApiDataToFormData(initialData));
  }, [initialData, reset]);

  const handleSubmit = async (data: ApplicationFormDto) => {
    try {
      const transformedApplication = transformFormDataToDto(data, [
        'moveInDate',
        'lookedAfter',
        'address',
        'homeStatus',
        'medicalDetails',
        'proofOfAddress',
        'localAuthorityDetails',
      ]);

      const applicationDto: UpdateApplicationDto = {
        ...transformedApplication,
        //need to convert it because of the wrong typing of the BE. it waits for Date object.
        moveInDate:
          has(transformedApplication, 'moveInDate') &&
          transformedApplication.moveInDate
            ? new Date(transformedApplication.moveInDate)
            : null,
        localAuthorityDetails: transformedApplication.localAuthorityDetails
          ? transformedApplication.localAuthorityDetails
          : undefined,
        //radio button does not support boolean, so need to convert string to boolean or null
        lookedAfter: {
          ...transformedApplication.lookedAfter,
          isSubjectToCourtDecision:
            has(
              transformedApplication,
              'lookedAfter.isSubjectToCourtDecision'
            ) && transformedApplication.lookedAfter?.isSubjectToCourtDecision
              ? transformedApplication.lookedAfter.isSubjectToCourtDecision ===
                'true'
              : null,
        },
      };

      await onSubmit(applicationDto);
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        const availableFields = getNestedKeys(form.getValues());

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }

      form.setError('root.server', {
        message: Array.isArray(httpError.message)
          ? httpError.message[0]
          : httpError.message,
      });
    }
  };

  const setDraft = (status: boolean) => {
    isDraft.current = status;
  };

  const saveAsDraft = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setDraft(true);
    await form.handleSubmit(handleSubmit)();
  };

  const handleSave = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setDraft(false);
    await form.handleSubmit(handleSubmit)();
  };

  return {
    form,
    handleSubmit: handleSave,
    defaultValues,
    saveAsDraft,
  };
}

export { useAdditionalChildDetailsForm };
