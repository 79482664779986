import { twMerge } from 'tailwind-merge';
import { Box } from '../box';
import { Tag } from '../tag';
import { format, formatDistanceToNow } from 'date-fns';
import {
  Attachment,
  TicketStatus,
  TicketTopic,
} from '@admissions-support/types';
import { messageCategories } from './message-categories';
import { MessageStatusBadge } from './message-status-badge';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useGetMessageAttachment } from '@/hooks/query-hooks/use-get-message-attachment';
import { MessageAttachment } from './message-attachment';

type MessageProps = {
  ticketId: string;
  isFirstMessage?: boolean;
  isParent?: boolean;
  title?: string;
  name: string;
  createdAt: string;
  message: string;
  topic?: TicketTopic;
  status?: TicketStatus;
  attachments?: Attachment[];
};

function Message({
  ticketId,
  isFirstMessage,
  isParent,
  title,
  name,
  createdAt,
  message,
  topic,
  status,
  attachments = [],
}: MessageProps) {
  const formattedDate = format(new Date(createdAt), 'dd/MM/yy HH:mm');
  const relativeDate = formatDistanceToNow(new Date(createdAt), {
    addSuffix: true,
  });

  const isTopRowShown = isFirstMessage && topic && status;

  const firstMessageBackgroundColorOverride = isParent
    ? 'bg-gray-100'
    : 'bg-gray-50';

  const [fileQueryKey, setFileQueryKey] = useState('');
  const {
    data: file,
    isSuccess: isFileSuccess,
    isError: isFileError,
  } = useGetMessageAttachment(
    { ticketId, fileKey: fileQueryKey },
    {
      enabled: !!fileQueryKey && !!ticketId,
    }
  );

  const fileUrl = isFileSuccess ? file?.signedUrl.toString() : undefined;

  useEffect(() => {
    if (isFileError) {
      toast.error('File preview failed. Please try again.');
    }

    if (!fileUrl) {
      return;
    }

    window.open(fileUrl, '_blank');
    setFileQueryKey('');
  }, [isFileError, fileUrl]);

  const handleDownload = async (key: string) => {
    setFileQueryKey(key);
  };

  return (
    <Box
      className={twMerge(
        'flex flex-col gap-3 bg-white p-3 sm:p-4',
        isFirstMessage && firstMessageBackgroundColorOverride
      )}
    >
      {isTopRowShown ? (
        <div className="flex items-center justify-between">
          {topic ? (
            <Tag
              type="default"
              className="flex items-center gap-2 bg-white shadow-xs"
            >
              {messageCategories[topic]}
            </Tag>
          ) : null}
          <MessageStatusBadge status={status} />
        </div>
      ) : null}
      <div className="flex flex-col">
        <div className="text-xs font-medium text-primary-900">{name}</div>
        <div className="text-tertiary-600 text-xs font-normal">
          <span className="italic">{relativeDate}</span> ({formattedDate})
        </div>
      </div>
      {title ? (
        <div className="text-sm font-medium text-primary-900">{title}</div>
      ) : null}
      <p className="text-tertiary-600 text-sm">{message}</p>
      {attachments.length > 0 ? (
        <div className="flex flex-row gap-2">
          {attachments.map(attachment => (
            <MessageAttachment
              key={attachment.key}
              fileKey={attachment.key}
              filename={attachment.filename}
              reference={attachment.reference}
              handleDownload={handleDownload}
            />
          ))}
        </div>
      ) : null}
    </Box>
  );
}

export default Message;
