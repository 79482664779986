import { Assignation } from '@/components/application/drawer/assignation';
import { Disclosure } from '@/components/disclosure';
import { FormColEmptyState } from '@/components/form-col-empty-state';
import { ApplicationNoteForm } from '@/components/form/application-note.form';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useFile } from '@/hooks/use-file';
import { Permission } from '@/types/auth';
import { Application, Child, Log } from '@admissions-support/types';
import { FileAttachment01 } from '@untitled-ui/icons-react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import { ApplicantDetails } from './applicant-details';
import { ChangelogItem } from './changelog-item';
import { ChoiceField } from './choice-field';
import { ParentDetails } from './parent-details';
import { PrescribedChoice } from './prescribed-choice';
import { SiblingDetails } from './sibling-details';
import { UpdateStartDateForm } from './update-start-date.form';

type DrawerContentProps = {
  application: Application;
  child: Child;
  changelog: Log[];
};

const localStorageKey = 'admit_application_view';

function DrawerContent(props: DrawerContentProps) {
  const { application, child, changelog } = props;

  const persistedDisclosureStatus = localStorage.getItem(localStorageKey);

  const [disclosureStatus, setDisclosureStatus] = useState<
    Record<string, boolean>
  >(() => {
    return persistedDisclosureStatus
      ? (JSON.parse(persistedDisclosureStatus) as Record<string, boolean>)
      : {};
  });

  const [queryKey, setQueryKey] = useState('');

  const {
    data: file,
    isSuccess: isFileSuccess,
    isError: isFileError,
  } = useFile(queryKey, undefined, {
    enabled: Boolean(queryKey),
  });
  const { hasPermission } = usePermissions();

  const fileUrl = isFileSuccess ? file.url.toString() : undefined;
  useEffect(() => {
    if (isFileError) {
      toast.error('File preview failed. Please try again.');
    }

    if (!fileUrl) {
      return;
    }

    window.open(fileUrl, '_blank');
    setQueryKey('');
  }, [isFileError, fileUrl]);

  const handleDownload = async (key: string) => {
    setQueryKey(key);
  };

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(disclosureStatus));
  }, [disclosureStatus]);

  const handleDisclosureState = (name: string): ((status: boolean) => void) => {
    return (status: boolean) =>
      setDisclosureStatus(prev => ({ ...prev, [name]: status }));
  };

  const getDisclosureStateByName = (name: string): boolean => {
    return disclosureStatus[name] ?? true;
  };

  const parentChoices = application.choices.filter(
    choice => choice.type === 'USER_DEFINED'
  );

  return (
    <div className="divide-y divide-gray-200 border-t border-gray-200">
      {hasPermission([Permission['application:update']]) && (
        <Disclosure
          title="Assignation"
          content={
            <Assignation
              applicationId={application.id}
              childId={child.id}
              carers={application.carers}
            />
          }
          defaultOpen={getDisclosureStateByName('assignation')}
          onChange={handleDisclosureState('assignation')}
        />
      )}
      <Disclosure
        title="Applicant"
        content={
          child &&
          application && (
            <ApplicantDetails
              dateOfBirth={child.dateOfBirth}
              sex={child.sex}
              lookedAfterStatus={application.lookedAfter}
            />
          )
        }
        defaultOpen={getDisclosureStateByName('applicant')}
        onChange={handleDisclosureState('applicant')}
      />
      {application?.carers.map((carer, index) => (
        <Disclosure
          key={`carer-${index}`}
          title={`Parent/Carer ${index + 1}`}
          content={<ParentDetails data={carer} />}
          onChange={handleDisclosureState(`carer_${index}`)}
          defaultOpen={getDisclosureStateByName(`carer_${index}`)}
        />
      ))}
      <Disclosure
        title="Location Choices"
        content={
          <div className="space-y-3">
            {parentChoices.map(choice => {
              return (
                <ChoiceField
                  schoolYearId={application.schoolYear.id.toString()}
                  choice={choice}
                  key={choice.id}
                  applicationId={application.id}
                />
              );
            })}
            {parentChoices.length < 1 ? (
              <FormColEmptyState
                title="No Choices"
                description="There are no choices available for this application"
              />
            ) : null}

            <PrescribedChoice application={application} />
          </div>
        }
        onChange={handleDisclosureState('location_choices')}
        defaultOpen={getDisclosureStateByName('location_choices')}
      />
      <Disclosure
        title="Siblings"
        content={
          application.siblings.length > 0 ? (
            <div className="space-y-2">
              {application?.siblings.map((sibling, index) => (
                <SiblingDetails data={sibling} key={`sibling-${index}`} />
              ))}
            </div>
          ) : (
            <FormColEmptyState
              title="No Sibling"
              description="There are no siblings to display for this application"
            />
          )
        }
        onChange={handleDisclosureState('siblings')}
        defaultOpen={getDisclosureStateByName('siblings')}
      />
      <Disclosure
        title="Additional details"
        content={
          application && application.additionalDetails ? (
            <p>{application?.additionalDetails}</p>
          ) : (
            <FormColEmptyState
              title="No Additional Details"
              description="There are no additional details provided by this application"
            />
          )
        }
        onChange={handleDisclosureState('additional_details')}
        defaultOpen={getDisclosureStateByName('additional_details')}
      />
      <Disclosure
        title="Start Date"
        content={
          <UpdateStartDateForm
            applicationId={application.id}
            startDate={application.startDate}
          />
        }
        onChange={handleDisclosureState('start_date')}
        defaultOpen={getDisclosureStateByName('start_date')}
      />
      <Disclosure
        title="Attached files"
        content={
          child && child.attachments.length > 0 ? (
            <div className="space-y-6">
              {child?.attachments.map(file => (
                <div
                  key={file.reference}
                  className="flex items-center justify-between rounded-lg border border-gray-200 p-4"
                >
                  <div className="flex">
                    <FileAttachment01
                      viewBox="0 0 24 24"
                      className="mr-4 h-6 w-6"
                      aria-hidden="true"
                    />
                    {file.filename}
                  </div>
                  <button
                    type="button"
                    onClick={() => handleDownload(file.key)}
                    className="btn btn-primary"
                  >
                    View
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <FormColEmptyState
              title="No Attached Files"
              description="There are no attached files provided by this application"
            />
          )
        }
        onChange={handleDisclosureState('attached_files')}
        defaultOpen={getDisclosureStateByName('attached_files')}
      />
      <Disclosure
        title="Notes"
        content={<ApplicationNoteForm applicationId={application.id} />}
        onChange={handleDisclosureState('notes')}
        defaultOpen={getDisclosureStateByName('notes')}
      />

      <Tooltip id="change-log-tooltip" place="top" className="tooltip" />
      <Disclosure
        title="Changelog"
        content={
          changelog.length > 0 ? (
            <div className="max-h-[500px] space-y-6 overflow-y-auto">
              {changelog.map((logItem, logIndex) => (
                <ChangelogItem
                  key={logItem.id}
                  data={logItem}
                  isLast={changelog.length - 1 === logIndex}
                />
              ))}
            </div>
          ) : (
            <FormColEmptyState title="No Changelog to display" />
          )
        }
        onChange={handleDisclosureState('changelog')}
        defaultOpen={getDisclosureStateByName('changelog')}
      />
    </div>
  );
}

export { DrawerContent };
