import { FormColEmptyState } from '@/components/form-col-empty-state';
import { ActivityLog } from '@admissions-support/types';
import { ActivitylogItem } from './activity-log-item';
import { Tooltip } from 'react-tooltip';
import { useExportActivityLog } from '@/hooks/query-hooks/use-activity-log-by-child-id';
import { downloadCSV } from '@/utils/reporting-utils';
import { FileDownload03 } from '@untitled-ui/icons-react';
import { Loading01 } from '@untitled-ui/icons-react';
import { twMerge } from 'tailwind-merge';

type ChangeLogSectionProps = {
  activityLogs: ActivityLog[];
  childId: string;
};

function ChangeLogSection(props: ChangeLogSectionProps) {
  const { activityLogs, childId } = props;

  const {
    data: activityLogBlob,
    refetch,
    isFetching,
  } = useExportActivityLog(childId);

  const handleClickDownloadCSV = async () => {
    await refetch();

    if (!activityLogBlob) {
      return;
    }

    downloadCSV(activityLogBlob, `activity-log-${childId}-`);
  };

  return (
    <div className="pt-6">
      <Tooltip id="change-log-tooltip" place="top" className="tooltip" />
      <div className="flex w-full justify-between">
        <p className="text-lg text-gray-900">Changelog</p>
        <button
          type="button"
          className="btn btn-secondary btn-icon flex items-center gap-1"
          disabled={isFetching}
          onClick={() => {
            handleClickDownloadCSV();
          }}
        >
          {isFetching ? (
            <Loading01
              className="leading-icon h-5 w-5 animate-spin"
              aria-hidden="true"
            />
          ) : (
            <FileDownload03
              aria-hidden="true"
              className={twMerge(
                'leading-icon h-5 w-5',
                isFetching ? 'text-gray-400' : 'text-gray-700'
              )}
            />
          )}
          Export to CSV
        </button>
      </div>
      {activityLogs.length > 0 ? (
        <div className="space-y-6 pt-6">
          {activityLogs.map((logItem, logIndex) => (
            <ActivitylogItem
              key={logItem.id}
              data={logItem}
              isLast={activityLogs.length - 1 === logIndex}
            />
          ))}
        </div>
      ) : (
        <FormColEmptyState title="No Changelog to display" />
      )}
    </div>
  );
}

export { ChangeLogSection };
