import { childKey } from '@/config/query-keys';
import { useChildBookingsInfo } from '@/hooks/query-hooks/use-child-bookings-info';
import { useUpdateChildMutation } from '@/hooks/update-hooks/use-update-child-mutation';
import { useModal } from '@/hooks/use-modal';
import { queryClient } from '@/libs/react-query';
import { Child, PaymentMethods } from '@admissions-support/types';
import { compact, map, startCase, toLower } from 'lodash-es';
import { ApplicationStatusTag } from '../application/application-status-tag';
import { Box } from '../box';
import { FallbackComponent } from '../fallback-component';
import { CheckboxBase } from '../form/common/checkbox';
import { ActivityLogDrawer } from './activity-log/activity-log-drawer';
import { CopyBookingIdBase } from './copy-booking-id';
import { TransactionModal } from './transaction-modal';

type PaymentsProps = {
  child: Child;
};

function Payments(props: PaymentsProps) {
  const { child } = props;

  const {
    data: bookingsMeta,
    isError: isBookingsMetaError,
    isSuccess: isBookingsMetaSuccess,
    isLoading: isBookingsMetaLoading,
  } = useChildBookingsInfo(child.id);

  const {
    isOpen: isActivityLogOpen,
    openModal: openActivityLog,
    closeModal: closeActivityLog,
  } = useModal();

  const {
    isOpen: isTransactionModalOpen,
    openModal: openTransactionModal,
    closeModal: closeTransactionModal,
  } = useModal();

  const { mutateAsync: updateChild, isPending: isChildUpdating } =
    useUpdateChildMutation(child.id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: childKey.list,
        });
      },
    });

  const handleUpdatePaymentMethod = async (
    newValue: boolean,
    method: PaymentMethods
  ) => {
    const newPaymentMethods = {
      ...child.extraHours.paymentMethods,
    };

    newPaymentMethods[method] = newValue;

    const currentData: PaymentMethods[] = compact(
      map(newPaymentMethods, (value: boolean, key: string) => {
        if (value) {
          return key as PaymentMethods;
        }
      })
    );

    await updateChild({ paymentMethods: currentData });
  };

  if (isBookingsMetaError) {
    return (
      <Box className="w-full p-5">
        <FallbackComponent />
      </Box>
    );
  }

  return (
    <Box className="flex w-full flex-col p-5">
      <div className="mb-6 flex flex-col items-center justify-between gap-4 sm:flex-row">
        <div className="flex gap-4">
          <p className="text-lg font-semibold text-gray-900">Payments</p>
          {!isBookingsMetaLoading &&
            isBookingsMetaSuccess &&
            bookingsMeta.nbOfDueBooking > 0 && (
              <ApplicationStatusTag status="PLACEMENT_REJECTED">
                {bookingsMeta.nbOfDueBooking} Payment
                {bookingsMeta.nbOfDueBooking > 1 ? 's' : ''} Due
              </ApplicationStatusTag>
            )}
        </div>
        <div className="space-x-6">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={openActivityLog}
          >
            Activity Log
          </button>
          <button
            className="btn btn-primary"
            type="button"
            onClick={openTransactionModal}
          >
            Record Transaction
          </button>
        </div>
      </div>
      <div className="flex flex-col items-center gap-6 sm:items-start">
        <p className="text-md font-semibold text-gray-900">Methods Available</p>
        <div className="grid w-full grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
          {map(
            child.extraHours.paymentMethods,
            (value: boolean, key: PaymentMethods) => {
              if (key === 'OTHER') {
                return;
              }
              const label =
                key === 'EPAY_CAPITA'
                  ? 'WLC Secure Payments'
                  : startCase(toLower(key));
              return (
                <div key={key}>
                  <CheckboxBase
                    checked={value}
                    onChange={e =>
                      handleUpdatePaymentMethod(e.target.checked, key)
                    }
                    disabled={isChildUpdating}
                    label={label}
                    name={key}
                    className="mb-4"
                  />
                  {key === 'TAX_FREE_CHILD_CARE' &&
                    child.extraHours.childCareReference?.trim() && (
                      <CopyBookingIdBase
                        childId={child.extraHours.childCareReference}
                      />
                    )}
                  {key === 'CHILDCARE_VOUCHERS' &&
                    child.extraHours.voucherProvider?.trim() && (
                      <CopyBookingIdBase
                        childId={child.extraHours.voucherProvider}
                      />
                    )}
                  {key === 'EPAY_CAPITA' && (
                    <CopyBookingIdBase childId={child.id} />
                  )}
                </div>
              );
            }
          )}
        </div>
      </div>
      <TransactionModal
        isOpen={isTransactionModalOpen}
        onClose={closeTransactionModal}
        child={child}
      />
      <ActivityLogDrawer
        onClose={closeActivityLog}
        isOpen={isActivityLogOpen}
        childId={child.id}
      />
    </Box>
  );
}

export { Payments };
