import { catchmentAreasKey, publicOrganisationKeys } from '@/config/query-keys';
import { queryClient } from '@/libs/react-query';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { getSubDomain } from '@/utils/get-subdomain';
import { UpdateCatchmentAreaDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateCatchmentArea(
  mutationOptions?: UseMutationOptions<void, HttpError, UpdateCatchmentAreaDto>
) {
  const subdomain = getSubDomain();

  return useMutation({
    mutationKey: catchmentAreasKey.update,
    mutationFn: data => organisationService.addCatchmentArea(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: publicOrganisationKeys.single(subdomain),
      });
    },
    ...mutationOptions,
  });
}

export { useUpdateCatchmentArea };
