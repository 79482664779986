import { Tag } from '@/components/tag';
import { generateClassByCapacity } from '@/utils/location-utils';
import {
  FlatResourceDemand,
  FlatResourceDemandReport,
} from '@admissions-support/types';
import { isFinite } from 'lodash-es';
import { twMerge } from 'tailwind-merge';

function DemandResourcingTableCell({
  resource,
  day,
}: {
  resource: FlatResourceDemandReport;
  day: keyof FlatResourceDemand;
}) {
  const dayDemandCapacity = resource.capacity[day];
  const { demand, capacity } = {
    // Add some defaults
    capacity: null,
    demand: null,
    ...dayDemandCapacity,
  };

  if (demand === null) {
    return <>-</>;
  }

  // These are most likely be numbers, but we want to display "-" if they are not numbers
  const demandDisplay = isFinite(demand) ? demand : '-';
  const capacityDisplay = isFinite(capacity) ? capacity : '-';

  let toolTipContent = '';
  if (capacity !== null) {
    toolTipContent = `${demandDisplay} demand for ${capacityDisplay} capacity`;
  } else if (day === 'any') {
    toolTipContent = `${demandDisplay} Applicants have selected Any day`;
  }

  return (
    <div>
      <div
        className="inline-flex items-center gap-2"
        data-tooltip-content={toolTipContent}
        data-tooltip-id="capacity-resourcing-tooltip"
      >
        <Tag
          type="default"
          className={twMerge(
            capacity !== null &&
              generateClassByCapacity(capacity || 0, demand || 0),
            'FOOO h-[24px] min-w-10 p-0 text-center font-mono text-sm'
          )}
        >
          {demand}
        </Tag>
        {capacity !== null && (
          <>
            <span className="text-sm font-medium text-gray-900">/</span>
            <Tag
              type="default"
              className="h-[24px] min-w-10 p-0 text-center font-mono text-sm"
            >
              {capacity}
            </Tag>
          </>
        )}
      </div>
    </div>
  );
}

export { DemandResourcingTableCell };
