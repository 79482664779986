import { locationKey } from '@/config/query-keys';
import { useSchoolYear } from '@/context/school-year.context';
import { useLeaveModal } from '@/hooks/use-leave-modal';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useUpdateLocationMutation } from '@/hooks/update-hooks/use-update-location-mutation';
import { useUpdateOperationMutation } from '@/hooks/update-hooks/use-update-operation-mutation';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import {
  Location,
  LocationStatus,
  Operation,
  UpdateLocationDto,
} from '@admissions-support/types';
import { kebabCase } from 'lodash-es';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { CheckboxItem } from '../application/checkbox-item';
import {
  RadioBoxBase,
  RadioBoxOption,
  RadioBoxOptionProps,
} from './common/radio-box';
import { Textarea } from './common/textarea';
import { useAddDefaultSessionData } from '@/hooks/update-hooks/use-add-default-session-data';
import { useLocationOperation } from '@/hooks/query-hooks/use-operation';

type LocationOverviewProps = {
  location: Location;
  operation: Operation;
};

function LocationOverviewForm(props: LocationOverviewProps) {
  const { location, operation } = props;
  const form = useForm<UpdateLocationDto>({
    defaultValues: {
      notes: location.notes || '',
      status: location.status,
    },
  });
  useLeaveModal({
    show: form.formState.isDirty,
  });
  const { hasPermission } = usePermissions();
  const { schoolYear } = useSchoolYear();

  const params = useParams<{ locationId: string }>();
  const { mutateAsync: update, isPending } = useUpdateLocationMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: locationKey.list,
        refetchType: 'active',
      });
    },
  });

  const {
    mutateAsync: addDefaultSessionData,
    isPending: isAddingDefaultSessionData,
  } = useAddDefaultSessionData();

  const handleAddDefaultSessionData = async () => {
    await addDefaultSessionData({
      locationId: params.locationId || '',
      schoolYearId: schoolYear.id,
    });
  };

  const operationId = operation?.id || '';
  const locationId = params.locationId || '';

  const { isPending: isOperationUpdating, mutateAsync: updateOperation } =
    useUpdateOperationMutation(
      { operationId },
      {
        onSuccess: data => {
          queryClient.setQueryData(
            locationKey.operation(location.id, schoolYear.id),
            data
          );
        },
      }
    );

  const submitHandler = async (data: any) => {
    //TODO: needs to update the package in order to avoid this
    const convertedData = data as UpdateLocationDto;

    if (!convertedData.notes) {
      convertedData.notes = null;
    }

    try {
      await update({ id: params.locationId || '', data: convertedData });
      form.reset(form.getValues(), { keepValues: true });
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        if (!form.formState.defaultValues) {
          return;
        }

        const availableFields = getNestedKeys(form.formState.defaultValues);

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }
    }
  };

  const handleUpdateDeffering = async () => {
    await updateOperation({
      data: {
        isDeferToWaitingListEnabled: !operation?.isDeferToWaitingListEnabled,
      },
    });
  };

  const handleStatusChange = async (status: string) => {
    const selectedStatus = status as LocationStatus;

    await update({
      id: locationId,
      data: {
        status: selectedStatus,
      },
    });
  };

  const statusOptions: RadioBoxOption[] = [
    {
      value: 'ACTIVE',
      Content: props => (
        <StatusOption
          {...props}
          name="Active"
          description="Location can accept applications"
        />
      ),
    },
    {
      value: 'INACTIVE',
      Content: props => (
        <StatusOption
          {...props}
          name="Inactive"
          description="Location cannot accept applications"
        />
      ),
    },
    {
      value: 'ARCHIVED',
      Content: props => (
        <StatusOption
          {...props}
          name="Archived"
          description="Location no longer in use"
        />
      ),
    },
  ];

  const locationAreasString = location.areas?.map(area => area.name).join(', ');

  const details = [
    { name: 'Location Type', value: location.type },
    { name: 'Seed Code', value: location.seedCode },
    { name: 'Service Provider Number', value: location.serviceProviderNumber },
    { name: 'Care Inspectorate Number', value: location.careInspectorNumber },
    { name: 'Contact Number', value: location.contactNumber },
    { name: 'Website', value: location.website },
    {
      name: 'Key Contact',
      value: `${location.keyContact.firstName} ${location.keyContact.lastName}`,
    },
    { name: 'Key Contact Email', value: location.keyContact.email },
    { name: 'Area', value: locationAreasString },
  ];
  const isFormDisabled =
    !hasPermission([Permission['location:update']]) || isPending;

  const { data: locationOperation } = useLocationOperation(locationId);

  const isNotCouncilLocation = location?.providerType !== 'COUNCIL';
  const isAutoPopulateEnabled =
    isNotCouncilLocation &&
    location?.sessions?.length === 0 &&
    locationOperation?.ratios?.length === 0 &&
    locationOperation?.resources?.length === 0;

  return (
    <div className="two-col-form">
      <div>
        <dl className="space-y-6">
          {details.map(detail => (
            <div key={kebabCase(detail.name)}>
              <dt className="font-medium leading-6 text-gray-700">
                {detail.name}
              </dt>
              <dd className="break-words leading-6">
                {detail.value ? detail.value : '-'}
              </dd>
            </div>
          ))}
        </dl>
      </div>
      <div className="col-span-2">
        <div>
          <FormProvider {...form}>
            <form
              onSubmit={form.handleSubmit(submitHandler)}
              className="mb-6 flex h-full flex-1 flex-col"
              id="update-location-form"
            >
              <Textarea
                label="Notes"
                name="notes"
                helperText="Add any notes relating to this location"
                placeholder="Your note goes here.."
                rows={4}
                disabled={form.formState.isSubmitting}
              />
              <button
                className="btn btn-primary self-end"
                disabled={
                  form.formState.isSubmitting || !form.formState.isDirty
                }
              >
                Save
              </button>
            </form>
          </FormProvider>
          <RadioBoxBase
            value={location.status}
            onChange={handleStatusChange}
            containerClassName="space-y-2"
            label="Location Status"
            options={statusOptions}
            disabled={isFormDisabled}
          />
          <p className="label mb-1.5 mt-6">Overrides</p>
          <CheckboxItem
            Content={() => (
              <div>
                <p className="mb-0.5 text-sm font-semibold">
                  Defer to Waiting List
                </p>
                <p className="text-sm">
                  If an application is matched with this location, set as
                  waiting list.
                </p>
              </div>
            )}
            checked={operation?.isDeferToWaitingListEnabled || false}
            onChange={handleUpdateDeffering}
            disabled={isOperationUpdating}
          />
          {isNotCouncilLocation ? (
            <div className="mt-6 flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
              <div>
                <div className="text-sm font-semibold">Populate location</div>
                <p className="text-sm">
                  Populate the location with capacities, resources and sessions,
                  enable all ratios.
                </p>
                {!isAutoPopulateEnabled && (
                  <p className="mt-2 text-sm text-gray-400">
                    Action not available, because the location already has
                    session data added. Remove them to be able to run this
                    action.
                  </p>
                )}
              </div>
              <button
                className="btn btn-primary w-36 min-w-36 whitespace-nowrap"
                disabled={isAddingDefaultSessionData || !isAutoPopulateEnabled}
                onClick={handleAddDefaultSessionData}
              >
                Populate Location
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function StatusOption(
  props: RadioBoxOptionProps & {
    name: string;
    description: string;
  }
) {
  const { checked, name, description } = props;

  return (
    <div>
      <span
        className={twMerge(
          checked ? 'text-primary-800' : 'text-gray-700',
          'block text-sm font-medium'
        )}
      >
        {name}
      </span>
      <span className="block text-sm text-primary-700">{description}</span>
    </div>
  );
}

export { LocationOverviewForm };
