import { useActivityLogsByChildId } from '@/hooks/query-hooks/use-activity-log-by-child-id';
import { Drawer } from '../../drawer';
import { ActivityLogNoteForm } from './activity-log-note.form';
import { Spinner } from '@/components/spinner';
import { ChangeLogSection } from './changelog-section';
import { isAdminDashboard } from '@/utils/is-dashboard';

type ActivityLogDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  childId: string;
};

function ActivityLogDrawer(props: ActivityLogDrawerProps) {
  const { onClose, isOpen, childId } = props;

  const {
    data: activityLogs,
    isError,
    isLoading,
    isSuccess,
  } = useActivityLogsByChildId(childId);

  return (
    <Drawer onClose={onClose} open={isOpen} title="Activity Log" size="3xl">
      {!isLoading ? (
        <>
          {isAdminDashboard() && <ActivityLogNoteForm childId={childId} />}
          {isError || !isSuccess ? null : (
            <ChangeLogSection activityLogs={activityLogs} childId={childId} />
          )}
        </>
      ) : (
        <Spinner className="m-auto" />
      )}
    </Drawer>
  );
}

export { ActivityLogDrawer };
