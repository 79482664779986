import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import {
  AssignationMeta,
  AssignChildDto,
  Child,
  CreateChildDto,
  ListChild,
  UpdateChildDto,
} from '@admissions-support/types';
import { AxiosResponse } from 'axios';

const getChild = async (id: string): Promise<Child> => {
  const response = await axiosClient.get<Child>(`/children/${id}`);

  return response.data;
};

const getChildByPassport = async (identifier: string): Promise<Child> => {
  // Encode any special characters like `#`
  const encodedIdentifier = encodeURIComponent(identifier);
  const response = await axiosClient.get<Child>(
    `/children/identify/${encodedIdentifier}`
  );

  return response.data;
};

const createChild = async (data: CreateChildDto): Promise<Child> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<Child>,
    CreateChildDto
  >(`/children/`, data);

  return response.data;
};

const updateChild = async (
  childId: string,
  data: UpdateChildDto
): Promise<Child> => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<Child>,
    UpdateChildDto
  >(`/children/${childId}`, data);

  return response.data;
};

/**
 *
 * Fetches all Children for a parent
 * User must be a parent
 */
const getChildren = async (): Promise<ListChild[]> => {
  const response = await axiosClient.get<HttpError, AxiosResponse<ListChild[]>>(
    `/children/`
  );

  return response.data;
};

const fetchChildrenByParentId = async (
  parentId: string
): Promise<ListChild[]> => {
  const response = await axiosClient.get<HttpError, AxiosResponse<ListChild[]>>(
    `/children/parent/${parentId}`
  );

  return response.data;
};

const assignEmailToChild = async (data: {
  email: string | null;
  childId: string;
}): Promise<void> => {
  await axiosClient.post<HttpError, AssignChildDto>(
    `/children/${data.childId}/assign`,
    { email: data.email },
    { disableToast: true }
  );
};

const getChildAssignationMeta = async (
  childId: string
): Promise<AssignationMeta> => {
  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<AssignationMeta>
  >(`/children/${childId}/assign`);

  return response.data;
};

export const childService = Object.freeze({
  getChild,
  getChildByPassport,
  createChild,
  updateChild,
  getChildren,
  assignEmailToChild,
  getChildAssignationMeta,
  fetchChildrenByParentId,
});
