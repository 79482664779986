import { getOperatingDays } from '@/utils/application-utils';
import { listRequestedDaysInSessionSchedule } from '@/utils/choice-utils';
import { formatOperatingDays } from '@/utils/format-operating-days';
import {
  Choice,
  OperatingDaySession,
  TableChoice,
} from '@admissions-support/types';

type AttendancePatternCellProps = {
  choice: Choice | TableChoice;
};

function getFormattedOperatingDays(
  choice: AttendancePatternCellProps['choice']
) {
  const operatingDays = listRequestedDaysInSessionSchedule(choice.sessions);

  if (choice.splitPlacement) {
    const splitPlacementDaysAvailable = choice.splitPlacement.sessions
      ? Object.keys(choice.splitPlacement.sessions)
      : null;

    const splitPlacementOperatingDays = splitPlacementDaysAvailable?.filter(
      day =>
        choice.splitPlacement?.sessions
          ? choice.splitPlacement.sessions[day as keyof OperatingDaySession]
          : false
    );

    const splitPlacementOperatingDaysToDisplay = formatOperatingDays(
      getOperatingDays(splitPlacementOperatingDays || [])
    );

    return {
      operatingDaysToDisplay: formatOperatingDays(
        getOperatingDays(operatingDays)
      ),
      splitPlacementOperatingDaysToDisplay,
    };
  }

  return {
    operatingDaysToDisplay: formatOperatingDays(
      getOperatingDays(operatingDays)
    ),
  };
}

function AttendancePatternCell(props: AttendancePatternCellProps) {
  const { choice } = props;

  const days = getFormattedOperatingDays(choice);

  if (days.splitPlacementOperatingDaysToDisplay) {
    return (
      <div>
        <div className="flex gap-2">
          <span className="text-primary-900">{choice.location.name}</span>
          <span>{days.operatingDaysToDisplay}</span>
        </div>
        {choice?.splitPlacement?.location ? (
          <div className="flex gap-2">
            <span className="text-primary-900">
              {choice.splitPlacement?.location?.name}
            </span>
            <span>{days.splitPlacementOperatingDaysToDisplay}</span>
          </div>
        ) : null}
      </div>
    );
  }

  return <span>{days.operatingDaysToDisplay}</span>;
}

export { AttendancePatternCell };
