import { staffRouterPath } from '@/config/route-paths.config';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useUser } from '@/hooks/query-hooks/use-user';
import { useUserGroups } from '@/hooks/query-hooks/use-user-groups';
import { useSuspendUserMutation } from '@/hooks/use-suspend-user-mutation';
import { resetUsersQuery } from '@/pages/platform-users/platform-users';
import { Permission } from '@/types/auth';
import { HttpError, isBadRequest } from '@/types/error';
import { getErrorMessage } from '@/utils/get-error-message';
import { getNestedKeys } from '@/utils/get-nested-keys';
import {
  CreateInvitationDto,
  Invitation,
  UpdateUserDto,
  User,
} from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { PermissionGuard } from './auth/permission-guard';
import { ConfirmDescruction } from './confirm-destruction';
import { Drawer } from './drawer';
import { MultiSelect } from './form/common/multi-select';
import { Select } from './form/common/select';
import { TextInput } from './form/common/text-input';

type UpsertUserDrawerProps =
  | {
      type: 'create';
      open: boolean;
      onSubmit: (dto: CreateInvitationDto) => Promise<Invitation>;
      isLoading?: boolean;
    }
  | {
      type: 'edit';
      open: boolean;
      onSubmit: (dto: UpdateUserDto) => Promise<User>;
      initialData?: User;
      isLoading?: boolean;
    };

const schema = yup
  .object({
    email: yup.string().email().required().label('Email'),
    firstName: yup.string().required().label('First Name'),
    lastName: yup.string().required().label('Last Name'),
    roleId: yup.string().required().label('User Group'),
    assignedLocationIds: yup
      .array()
      .required()
      .of(yup.string().required())
      .label('Locations'),
  })
  .required();

const initialValue = {
  firstName: '',
  lastName: '',
  email: '',
  roleId: '',
  assignedLocationIds: [],
};

function UpsertUserDrawer(props: UpsertUserDrawerProps) {
  const { open, type, onSubmit, isLoading } = props;
  const navigate = useNavigate();
  const form = useForm<CreateInvitationDto>({
    resolver: yupResolver(schema),
    defaultValues: initialValue,
  });
  const {
    mutateAsync: suspendUserMutation,
    isPending: isSuspendUserMutationLoading,
    error: suspendUserMutationError,
  } = useSuspendUserMutation({
    onSuccess: () => {
      resetUsersQuery();
      handleClose();
    },
  });
  const { hasPermission } = usePermissions();
  const {
    data: locations,
    isLoading: isLocationsLoading,
    isSuccess: isLocationsSuccess,
  } = useLocations();
  const params = useParams<{ id?: string }>();

  const {
    isLoading: isUserLoading,
    data,
    isError,
    isSuccess: isGetUserSuccess,
  } = useUser(params?.id || '', {
    enabled: type === 'edit' && Boolean(params.id),
  });

  useEffect(() => {
    if (isError) {
      navigate(staffRouterPath.PLATFORM_USERS);
    }
  }, [isError, navigate]);

  useEffect(() => {
    if (!isGetUserSuccess || type !== 'edit') {
      return;
    }

    form.reset({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      roleId: data.role.id.toString(),
      assignedLocationIds: data.assignedLocationIds || [],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetUserSuccess, data]);

  const {
    data: userGroupsData,
    isLoading: isUserGroupsLoading,
    isSuccess: isUserGroupsSuccess,
  } = useUserGroups();

  useEffect(() => {
    if (isUserGroupsSuccess) {
      if (userGroupsData.length < 1 || type === 'edit') {
        return;
      }

      form.reset({
        ...form.getValues(),
        roleId: userGroupsData[0].id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserGroupsSuccess, userGroupsData]);

  const userGroups =
    userGroupsData && userGroupsData.length > 0
      ? userGroupsData.map(group => ({ key: group.id, value: group.name }))
      : [];

  const handleSubmit = async (createInvitationDto: CreateInvitationDto) => {
    try {
      await onSubmit(createInvitationDto);
      handleClose();
    } catch (error) {
      const httpError = error as HttpError;

      if (httpError.statusCode === 409) {
        form.setError('email', { message: httpError.message });
      }

      if (isBadRequest(httpError)) {
        if (!form.formState.defaultValues) {
          return;
        }

        const availableFields = getNestedKeys(form.formState.defaultValues);

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }
    }
  };

  const handleClose = () => {
    navigate(staffRouterPath.PLATFORM_USERS);
  };

  const resetForm = () => {
    form.reset(initialValue);
  };

  const isFormDisabled =
    isLoading || !hasPermission([Permission['users:update']]);

  const locationsOptions = isLocationsSuccess
    ? locations.map(location => ({
        label: location.name,
        value: location.id,
      }))
    : [];

  return (
    <Drawer
      open={open}
      title={type === 'edit' ? 'Edit User' : 'Create User'}
      description="All fields are required"
      onClose={handleClose}
      isLoading={isUserGroupsLoading || isUserLoading}
      onDrawerClosed={resetForm}
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="flex h-full flex-1 flex-col"
        >
          <div className="space-y-5">
            <TextInput
              name="firstName"
              label="First Name*"
              type="text"
              placeholder="First Name"
              disabled={isFormDisabled}
            />
            <TextInput
              name="lastName"
              label="Last Name*"
              type="text"
              placeholder="Last Name"
              disabled={isFormDisabled}
            />
            <TextInput
              name="email"
              label="Email*"
              type="email"
              placeholder="user@email.com"
              disabled={isFormDisabled}
            />
            <Select
              name="roleId"
              label="User Group*"
              options={userGroups}
              disabled={isFormDisabled}
            />
            <MultiSelect
              name="assignedLocationIds"
              label="Assigned Locations"
              helperText="Users can only access the locations they are assigned to. If left empty, the user will have access to all locations."
              placeholder="Select..."
              options={locationsOptions}
              isDisabled={isLoading || isLocationsLoading}
            />
            {data ? (
              <PermissionGuard
                requiredPermissions={[Permission['users:suspend']]}
              >
                <div className="!mt-8">
                  {type === 'edit' && data && data.isSuspended === false ? (
                    <ConfirmDescruction
                      buttonText="Suspend User"
                      actionButtonText="Confirm Suspension"
                      declineButtonText="Cancel"
                      title="Confirm Suspend User"
                      text="Suspending this user will revoke their access to the platform immediately. This action is reversible."
                      action={() =>
                        suspendUserMutation({
                          userId: data.id,
                          isSuspended: true,
                        })
                      }
                      isLoading={isSuspendUserMutationLoading}
                      error={getErrorMessage(suspendUserMutationError)}
                    />
                  ) : (
                    <ConfirmDescruction
                      buttonText="Unsuspend User"
                      actionButtonText="Confirm Unsuspending User"
                      declineButtonText="Cancel"
                      title="Confirm Unsuspend User"
                      text="Unsuspending this user will grant them access to the platform immediately. This action is reversible."
                      action={() =>
                        suspendUserMutation({
                          userId: data.id,
                          isSuspended: false,
                        })
                      }
                      isLoading={isSuspendUserMutationLoading}
                      error={getErrorMessage(suspendUserMutationError)}
                    />
                  )}
                </div>
              </PermissionGuard>
            ) : null}
          </div>
          <div className="mt-auto flex justify-end gap-3">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleClose}
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isFormDisabled}
            >
              {type === 'create' ? 'Invite' : 'Save'} User
            </button>
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
}

export { UpsertUserDrawer };
