import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import { AddDefaultSessionDataParamsDto } from '@admissions-support/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

function useAddDefaultSessionData(
  mutationOptions?: Partial<
    UseMutationOptions<
      void,
      HttpError,
      AddDefaultSessionDataParamsDto & { locationId: string }
    >
  >
) {
  return useMutation({
    mutationFn: locationService.addDefaultSessionData,
    ...mutationOptions,
  });
}

export { useAddDefaultSessionData };
