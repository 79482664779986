import { ApplicationStatusTag } from '@/components/application/application-status-tag';
import { Box } from '@/components/box';
import { FallbackComponent } from '@/components/fallback-component';
import { Toggle } from '@/components/form/common/toggle';
import { LoadingScreen } from '@/components/loading-screen';
import { Modal } from '@/components/modal';
import { PaymentStatusTag } from '@/components/payment-status-tag';
import { Table } from '@/components/table';
import { parentRouterPath } from '@/config/route-paths.config';
import { useChildBookings } from '@/hooks/query-hooks/use-child-bookings';
import { useChildBookingsInfo } from '@/hooks/query-hooks/use-child-bookings-info';
import { useTerms } from '@/hooks/query-hooks/use-terms';
import { useCancelBooking } from '@/hooks/use-cancel-booking';
import { queryClient } from '@/libs/react-query';
import { formatCurrency } from '@/utils/format-currency';
import { getErrorMessage } from '@/utils/get-error-message';
import { isAdminDashboard } from '@/utils/is-dashboard';
import { BookingListing } from '@admissions-support/types';
import {
  PaginationState,
  SortingState,
  createColumnHelper,
} from '@tanstack/react-table';
import { SearchRefraction } from '@untitled-ui/icons-react';
import { differenceInDays, format, isBefore, isFuture } from 'date-fns';
import { startCase } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

type ChildExtraHoursTableFilter = {
  showPastBookings: boolean;
};

function ChildExtraHoursTable() {
  const params = useParams<{ id: string }>();
  const childId = params.id || '';
  const columnHelper = createColumnHelper<BookingListing>();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [cancelModal, setCancelModal] = useState<string | null>(null);

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'date',
      desc: false,
    },
  ]);

  const [filter, setFilter] = useState<ChildExtraHoursTableFilter>({
    showPastBookings: false,
  });

  const { data, isLoading, isSuccess, error, isFetching } = useChildBookings({
    childId: childId,
    pagination,
    sorting,
    filter,
  });

  const {
    data: bookingMeta,
    isSuccess: isBookingMetaSuccess,
    isLoading: isBookingMetaLoading,
  } = useChildBookingsInfo(childId);

  const {
    data: schoolYears,
    isSuccess: isSchoolYearsSuccess,
    isLoading: isSchoolYearsLoading,
    isError: isSchoolYearsError,
  } = useTerms();

  const {
    mutateAsync,
    isPending: isBookingCancelling,
    error: cancellingError,
  } = useCancelBooking();

  const columns = useMemo(
    () => [
      columnHelper.accessor('date', {
        id: 'date',
        cell: info => {
          const formattedDate = format(new Date(info.getValue()), 'dd/MM/yy');
          return <span className="font-mono">{formattedDate}</span>;
        },
        header: () => <span>Date</span>,
        enableSorting: true,
        size: 116,
      }),
      columnHelper.accessor('location.name', {
        id: 'location',
        cell: info => {
          return info.getValue();
        },
        header: () => <span>Location</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('productName', {
        cell: info => {
          return info.getValue();
        },
        header: () => <span>Product Name</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('time', {
        cell: info => {
          return <span className="font-mono">{info.getValue()}</span>;
        },
        header: () => <span>Time</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('price', {
        cell: info => {
          return (
            <span className="font-mono">
              {formatCurrency(Number(info.getValue()))}
            </span>
          );
        },
        header: () => <span>Cost</span>,
        enableSorting: false,
        size: 99,
      }),

      columnHelper.accessor('paymentStatus', {
        cell: info => {
          if (info.row.original.status === 'CANCELLED') {
            return (
              <ApplicationStatusTag status="PLACEMENT_REJECTED">
                Cancelled
              </ApplicationStatusTag>
            );
          }

          const paymentStatus = info.getValue();
          return (
            <PaymentStatusTag status={paymentStatus}>
              {startCase(paymentStatus.toLowerCase())}
            </PaymentStatusTag>
          );
        },
        header: () => <span>Status</span>,
        enableSorting: false,
        size: 173,
      }),

      columnHelper.accessor('id', {
        id: 'actions',
        cell: info => {
          const bookingDate = new Date(info.row.original.date);
          const currentDate = new Date();

          // you cannot cancel a booking which is in the past
          const isPastBooking = isBefore(bookingDate, currentDate);

          const daysDifference = differenceInDays(bookingDate, currentDate);

          // you cannot cancel a booking which is within 4 weeks
          const isCancellationPeriodPast =
            daysDifference <= 28 && !isAdminDashboard();

          const isButtonEnabled =
            (!isPastBooking && !isCancellationPeriodPast) ||
            (isAdminDashboard() && !isPastBooking);

          // you cannot cancel booking two times
          if (info.row.original.status === 'CANCELLED') {
            return null;
          }

          return (
            <button
              type="button"
              className={twMerge(
                'link',
                isCancellationPeriodPast &&
                  !isPastBooking &&
                  'view-cancellation-period-past-tooltip',
                isPastBooking && 'view-past-booking-tooltip',
                !isButtonEnabled && 'text-gray-400'
              )}
              onClick={() => setCancelModal(info.getValue())}
              disabled={!isButtonEnabled}
            >
              Cancel Booking
            </button>
          );
        },
        header: () => <span>Actions</span>,
        enableSorting: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const toggleShowPastBookings = () => {
    setPagination(previousPagination => ({
      ...previousPagination,
      pageIndex: 0,
    }));

    setFilter(oldValue => ({
      ...oldValue,
      showPastBookings: !oldValue.showPastBookings,
    }));
  };

  const handleCancelBooking = async () => {
    if (!cancelModal) {
      return;
    }

    await mutateAsync({ bookingId: cancelModal });
    setCancelModal(null);
    await queryClient.invalidateQueries({
      queryKey: ['booking', 'child', 'meta'],
    });
    await queryClient.invalidateQueries({
      queryKey: ['booking', 'child', childId],
    });
  };

  const isBookingDisabled =
    isBookingMetaLoading ||
    !isBookingMetaSuccess ||
    !bookingMeta.isBookingEnabled ||
    isSchoolYearsLoading ||
    !isSchoolYearsSuccess;

  if (isSchoolYearsLoading) {
    return <LoadingScreen />;
  }

  if (isSchoolYearsError || !isSchoolYearsSuccess) {
    return <FallbackComponent />;
  }

  const filteredSchoolYears = schoolYears.filter(schoolYear =>
    isFuture(new Date(schoolYear.endDate))
  );

  return (
    <Box>
      <div className="flex items-center justify-between">
        <p className="mb-6 flex items-center text-lg font-semibold">Bookings</p>
        {isBookingDisabled && !isAdminDashboard() && (
          <>
            <Tooltip
              anchorSelect=".disabled-booking-button"
              content="Booking is disabled for this Child"
              place="top"
              className="tooltip"
            />
            <p className="btn btn-secondary disabled-booking-button cursor-not-allowed select-none text-gray-300 ring-gray-200 hover:bg-white">
              New Booking
            </p>
          </>
        )}
        {!isBookingDisabled && !isAdminDashboard() && schoolYears && (
          <Link
            to={generatePath(
              `${parentRouterPath.CHILD_NEW_BOOKING}?termId=${filteredSchoolYears[0].id}`,
              {
                id: childId,
              }
            )}
            className="btn btn-primary"
          >
            New Booking
          </Link>
        )}
      </div>
      <Toggle
        value={filter.showPastBookings}
        label="Show Past Bookings"
        onChange={toggleShowPastBookings}
      />
      <Tooltip
        anchorSelect=".view-cancellation-period-past-tooltip"
        content="The cancellation period has past. It is less than 4 weeks until booked session."
        place="top"
        className="tooltip"
      />
      <Tooltip
        anchorSelect=".view-past-booking-tooltip"
        content="This session has passed"
        place="top"
        className="tooltip"
      />
      <Table
        columns={columns}
        data={isSuccess ? data.docs : []}
        className="mt-5"
        isFetching={isFetching}
        isLoading={isLoading}
        error={getErrorMessage(error)}
        EmptyState={
          <div className="py-12 text-center">
            <SearchRefraction
              className="mx-auto h-8 w-8 text-gray-400"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No Bookings Found
            </h3>
          </div>
        }
        sortingType="server"
        sorting={sorting}
        onSortingChange={setSorting}
        paginationType="manual"
        onPaginationChange={setPagination}
        pagination={pagination}
        pageCount={isSuccess ? data.totalPages : 0}
      />
      <Modal
        open={Boolean(cancelModal)}
        onClose={() => setCancelModal(null)}
        title="Cancel Booking"
        description="Are you sure you want to cancel this booking? This action cannot be undone."
        primaryBtnText="Cancel Booking"
        primaryAction={handleCancelBooking}
        secondaryBtnText="Keep Booking"
        secondaryAction={() => setCancelModal(null)}
        type="error"
        error={getErrorMessage(cancellingError)}
        isLoading={isBookingCancelling}
      />
    </Box>
  );
}

export { ChildExtraHoursTable };
export type { ChildExtraHoursTableFilter };
