import * as yup from 'yup';

const upsertCatchmentAreasFormSchema = yup.object({
  catchmentAreaFiles: yup
    .array(
      yup.object({
        filename: yup.string(),
        reference: yup.string(),
        key: yup.string().required(),
      })
    )
    .required()
    .max(1, 'Catchment Area can only have 1 file'),
});

export { upsertCatchmentAreasFormSchema };
